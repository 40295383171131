import { useState, useEffect } from "react";
import { gsap } from "gsap";

import Heart from "./components/Heart";

import { Container } from "./styles";
import EventEmitter from "../../../experience/Utils/EventEmitter";
import { useMatomo } from "@datapunt/matomo-tracker-react";

// const Step0 = ({ setStep }: { setStep: (val: number) => void }) => {
//   const nextStep = () => setStep(1)
const Step0 = ({
  setStep,
  step,
  granted,
  progress,
  ready,
  handlePermission,
}: {
  setStep: (val: number) => void;
  step: number;
  granted: boolean | null;
  ready: boolean;
  progress: number;
  handlePermission: () => void;
}) => {
  useEffect(() => {
    if (granted !== null) {
      if (granted) {
        const tl = gsap.timeline();

        tl.to(["#cam-btn", "#text-btn"], {
          opacity: 0,
          duration: 0.25,
          ease: "power3.out",
        });

        tl.to(".progress", {
          opacity: 1,
          duration: 0.25,
          ease: "power3.out",
        });
      } else {
        setStep(1);
      }
    }
  }, [granted]);

  useEffect(() => {
    if (ready) {
      gsap.to("#step0", {
        opacity: 0,
        display: "none",
        duration: 0.2,
        ease: "power3.out",
        onComplete: () => EventEmitter.on("ready", true),
      });
    }
  }, [ready]);
  return (
    <Container id="step0" step={step}>
      <div>
        <h1 className="title">Schön, dass Sie sich bei uns informieren.</h1>
      </div>

      <div>
        <p className="text">
          Erfahren Sie jetzt mehr über das Thema “Herz” und Vorhofflimmern.
          <br></br>
          Egal wofür Ihr Herz schlägt - wir möchten, dass es im Rhythmus bleibt.
        </p>
        <p className="text-ready">Sind Sie bereit?</p>
      </div>

      <div className="heart">
        <div className="container">
          <Heart />
        </div>
      </div>

      <div className="next">
        {granted ? (
          <div className="progress">
            <div
              className="progress-bar"
              style={{
                height: "0.7em",
                width: `${progress}%`,
              }}></div>
            <span className="progress-text">{progress.toFixed(0)}%</span>
          </div>
        ) : (
          <>
            <button id="cam-btn" onClick={handlePermission}>
              Los geht´s!
            </button>
          </>
        )}
      </div>
    </Container>
  );
};

const Step1 = ({
  step,
  granted,
  progress,
  ready,
  handlePermission,
}: {
  step: number;
  granted: boolean | null;
  ready: boolean;
  progress: number;
  handlePermission: () => void;
}) => {
  useEffect(() => {
    if (granted) {
      const tl = gsap.timeline();

      tl.to(["#cam-btn", "#text-btn"], {
        opacity: 0,
        duration: 0.25,
        ease: "power3.out",
      });

      tl.to(".progress", {
        opacity: 1,
        duration: 0.25,
        ease: "power3.out",
      });
    }
  }, [granted]);

  useEffect(() => {
    if (ready) {
      gsap.to("#step1", {
        opacity: 0,
        display: "none",
        duration: 0.2,
        ease: "power3.out",
        onComplete: () => EventEmitter.on("ready", true),
      });
    }
  }, [ready]);

  return (
    <Container id="step1" step={step}>
      <div>
        <h1 className="title">Der Kamera Zugriff erlauben</h1>
      </div>

      <div>
        <p className="text">
          Im Folgenden möchten wir Ihnen gern die Erkrankung des Vorhofflimmern
          näher erläutern. Durch die Einbindung einer Augmented Reality - oder
          auch 3D-Umgebung - können Sie die Informationen nicht nur lesen,
          sondern auch in einem Körper animiert sehen und damit interagieren.
          <br></br>
          <br></br>
          Hierfür müssen Sie nur den Kamerazugriff erlauben.
        </p>
      </div>

      <div className="next">
        {granted ? (
          <div className="progress">
            <div
              className="progress-bar"
              style={{
                height: "0.7em",
                width: `${progress}%`,
              }}></div>
            <span className="progress-text">{progress.toFixed(0)}%</span>
          </div>
        ) : (
          <>
            <button id="cam-btn" onClick={handlePermission}>
              Kamera Zugriff erlauben
            </button>

            <span id="text-btn">Der Kamera Zugriff verweigern</span>
          </>
        )}
      </div>
    </Container>
  );
};

const isFirstStep = (step: number): boolean => step === 0;

const Welcome = ({
  handlePermission,
  granted,
  progress,
  ready,
}: {
  handlePermission: () => void;
  granted: boolean | null;
  progress: number;
  ready: boolean;
}) => {
  const [step, setStep] = useState(0);
  const { trackEvent, trackPageView } = useMatomo();

  const handleClick = () => {
    trackEvent({
      action: "tap event",
      category: "launch",
      name: isFirstStep(step) ? "tap launch button" : "tap retry permissions",
    });

    handlePermission();
  };

  useEffect(() => trackPageView({ documentTitle: "Splash Page" }), []);

  /**
   * Step 0 - Landing text
   */
  if (isFirstStep(step))
    return (
      <Step0
        handlePermission={handleClick}
        {...{ setStep, step, granted, progress, ready }}
      />
    );

  /**
   * Step 1 - Camera authorisation
   */
  return (
    <Step1
      handlePermission={handleClick}
      {...{ step, granted, progress, ready }}
    />
  );
};

export default Welcome;
