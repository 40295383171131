import styled from 'styled-components'
import { Color } from '../../../../../../config/styles/colors'

type Props = { active?: boolean }

export const Tabs = styled.div`
  overflow: hidden;
  font-family: Open Sans;
  height: 5em;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  background: #cedfe3;

  .menus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .text {
    font-family: PTSans-Regular;
    font-size: 14px;
    color: #333333;

    > span {
      line-height: 20px;
    }

    .url {
      font-family: PTSans-Bold;
    }
  }
`

export const Tab = styled.button<Props>`
  border: none;
  outline: none;
  cursor: pointer;

  padding: 0.5em;
  width: 4.25em;
  height: 4.25em;

  margin: 0 0.4em;

  box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.21);
  border-radius: 0.625em;

  background-color: ${({ active }) => (!active ? '#ffffff' : Color.PRIMARY)};
`

export const Content = styled.div<Props>`
  height: 100%;
  color: ${Color.PRIMARY};
  ${({ active }) => (active ? '' : 'display:none')}
`
