import * as THREE from 'three'
import Experience from '.'

class Background {
  private experience = new Experience()
  public instance!: THREE.Mesh

  constructor() {
    this.init()
  }

  private init() {
    const { scene, config, renderer } = this.experience

    const aspect = config.screen.aspectRatio
    const rect = renderer.instance.domElement.getBoundingClientRect()

    const width = rect.width
    const height = rect.height

    const planeGeometry = new THREE.PlaneGeometry(1, 1)
    const planeMaterial = new THREE.MeshBasicMaterial({
      transparent: true,
      opacity: 0.2,
      side: THREE.DoubleSide,
      color: 0x000000,
    })

    this.instance = new THREE.Mesh(planeGeometry, planeMaterial)

    const adaptedHeight =
      height *
      (aspect > width / height ? rect.width / width : rect.height / height)
    const adaptedWidth =
      width *
      (aspect > width / height ? rect.width / width : rect.height / height)

    this.instance.scale.x = adaptedWidth
    this.instance.scale.y = adaptedHeight

    this.instance.position.z = -10

    scene.add(this.instance)
  }
}

export default Background
