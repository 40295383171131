import styled from 'styled-components'

export const AppLayout = styled.div<{ granted?: boolean | null }>`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;

  .page-header {
    height: 2em;
    flex: 0 0 auto;

    background: #ffffff;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.8em 1.5em;
  }

  .page-content {
    flex: 1 1 auto;
    position: relative; /* need this to position inner content */
    overflow-y: auto;

    display: flex;
    flex-direction: column;
  }

  .page-footer {
    height: 2em;
    flex: 0 0 auto;
    background: #ffffff;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0.5em;
  }
`
