import * as THREE from 'three'
//import { AnimationAction } from 'three'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'

// These are the frame ranges:
// frame 1-70 Idle to Measuring Pulse
// frame 70-140 Measuring Pulse idle (loopable) //
// frame 120-180 Measuring Pulse to Idle
// frame 180-280 Breathing idle (loopable) //
// At 30fps

type BodyAnimationName = 'healthy_idle' | 'unhealthy_idle' | 'unhealthy_pulse';
type Animation = {
  name: BodyAnimationName
  animation: THREE.AnimationAction
}
type Animations = Animation[]

type SceneMixer = {
  name: BodyAnimationName
  mixer: THREE.AnimationMixer
}

type SceneMixers = SceneMixer[]

type ActiveBodyAnim = {
  name: string
  animation: THREE.AnimationAction
}

class BodyAnimationsInstance {
  private animations: Animations = []
  private sceneMixers: SceneMixers = []

  private activeBodyAction!: ActiveBodyAnim
  private previousBodyAction!: ActiveBodyAnim
  private activePulseAction!: ActiveBodyAnim

  private activeHealthyIdleAction!: THREE.AnimationAction
  private activeUnHealthyIdleAction!: THREE.AnimationAction
  private activeUnHealthyPulseAction!: THREE.AnimationAction

  public add(model: GLTF, name: BodyAnimationName) {
    const isUnhealthyPulse = name === 'unhealthy_pulse'

    const isIdleHealthy = name === 'healthy_idle'

    const modelAnimations = model.animations
    const scene = model.scene

    const startFrame = isUnhealthyPulse ? 240 : (isIdleHealthy ? 140 : 370);
    const endFrame = isUnhealthyPulse ? 370 : (isIdleHealthy ? 220 : 450);
    const fps = 30;

    const mixer = new THREE.AnimationMixer(scene)

    const clip = THREE.AnimationUtils.subclip(
      modelAnimations[0],
      'All Animations',
      startFrame,
      endFrame,
      fps
    )

    const action = mixer.clipAction(clip)

    this.sceneMixers.push({
      name,
      mixer,
    })

    this.animations.push({
      name,
      animation: action,
    })

    // Set default state as idle
    if (isIdleHealthy) {
      action.play().paused = true
      this.activeBodyAction = {
        name,
        animation: action,
      }
    }
  }

  public play(name: BodyAnimationName) {
    const action = this.animations.find((a) => a.name === name) as Animation
    action.animation.play()
  }

  public stop() {
    this.sceneMixers.forEach(({ mixer, name }) => {
      mixer.stopAllAction()
    })
  }
  public fadeToAction(name: string, duration = 0.5) {
    const { animation } = this.animations.find(
      (a) => a.name === name
    ) as Animation

    switch (name) {
      case 'healthy_idle':
        if (this.activeUnHealthyIdleAction?.isRunning()) {
          this.activeUnHealthyIdleAction?.stop()
        }
        if (this.activeUnHealthyPulseAction?.isRunning()) {
          this.activeUnHealthyPulseAction?.stop()
        }
        this.activeHealthyIdleAction = animation
        this.stop()
        animation.play();


        break
      case 'unhealthy_idle':
        if (this.activeHealthyIdleAction?.isRunning()) {
          this.activeHealthyIdleAction?.stop()
        }
        if (this.activeUnHealthyPulseAction?.isRunning()) {
          this.activeUnHealthyPulseAction?.stop()
        }
        this.activeUnHealthyIdleAction = animation
        this.stop()
        animation.play();

        break
      case 'unhealthy_pulse':
        if (this.activeUnHealthyIdleAction?.isRunning()) {
          this.activeUnHealthyIdleAction?.stop()
        }
        if (this.activeHealthyIdleAction?.isRunning()) {
          this.activeHealthyIdleAction?.stop()
        }
        this.activeUnHealthyPulseAction = animation
        this.stop()
        animation.play();

        this.activeUnHealthyPulseAction.clampWhenFinished = true
        // this.activeBodyAction.animation
        //   .reset()
        //   .setEffectiveTimeScale(1)
        //   .setEffectiveWeight(1)
        //   .fadeIn(duration)
        //   .play()

        //     if (animation.paused) {
        //       animation.paused = false
        //     }

        //     if (this.activeBodyAction?.name === 'unhealthy_pulse') {
        //       this.activeBodyAction?.animation.fadeOut(duration)
        //       this.activeBodyAction = {
        //         name,
        //         animation,
        //       }

        //       this.activeBodyAction.animation.clampWhenFinished = true
        //       this.activeBodyAction.animation
        //         .reset()
        //         .setEffectiveTimeScale(1)
        //         .setEffectiveWeight(1)
        //         .fadeIn(duration)
        //         .play()
        //     }
        break
      default:
        this.previousBodyAction = this.activeBodyAction
        this.activeBodyAction = {
          name,
          animation,
        }

        if (this.previousBodyAction !== this.activeBodyAction) {
          this.previousBodyAction?.animation.fadeOut(duration)
        }

        this.activeBodyAction.animation.clampWhenFinished = true
        this.activeBodyAction.animation
          .reset()
          .setEffectiveTimeScale(1)
          .setEffectiveWeight(1)
          .fadeIn(duration)
          .play()
    }

  }




  public update(delta: number) {
    this.sceneMixers.forEach(({ mixer }) => mixer.update(delta))
  }
}

const BodyAnimations = new BodyAnimationsInstance()
export default BodyAnimations

