import * as THREE from 'three'
import { gsap } from 'gsap'

import Experience from '..'
import { Color } from '../../config/styles/colors'
import EventEmitter from '../Utils/EventEmitter'

import glowSrc from '../../assets/Images/glow.png'
import handSrc from '../../assets/Images/hand.png'

type ObjectSelected = {
  active: THREE.Mesh | null
  previous: THREE.Mesh | null
}

class Hotspot {
  private experience = new Experience()
  private parentPoint!: THREE.Mesh
  private innerPoint!: THREE.Mesh
  private glowPoint!: THREE.Mesh
  private hand!: THREE.Mesh
  private rect!: THREE.Mesh

  private selected!: number
  public highlightedHotspot!: number

  private zOffset = 0.2

  private geometry = new THREE.CircleGeometry(5, 40)
  private hotspotGroup = new THREE.Group()
  private raycaster = new THREE.Raycaster()
  private defaultColor = new THREE.Color(Color.SECONDARY)
  private selectedColor = new THREE.Color(Color.SLATEGRAY)

  // Hotspot materials
  public parentMats: THREE.MeshStandardMaterial[] = []
  public innerMats: THREE.MeshStandardMaterial[] = []
  public glowMats: THREE.MeshStandardMaterial[] = []
  public handMats: THREE.MeshStandardMaterial[] = []

  // Raycaster targets
  private objects: THREE.Object3D[] = []
  private object: ObjectSelected = {
    active: null,
    previous: null,
  }
  private hotspots: ObjectSelected = {
    active: null,
    previous: null,
  }

  constructor(parent: THREE.Group) {
    this.init(parent)
  }

  private init(parent: THREE.Group) {
    parent.add(this.hotspotGroup)

    const {
      position: { z },
    } = parent

    this.zOffset += z

    this.createClickableArea()

    window.addEventListener(
      'touchstart',
      this.handleTouchStart.bind(this),
      false
    )

    this.selected = -1

    EventEmitter.on('hotspot.close', this.handleCloseCard.bind(this))
  }

  private resetObjectSelected() {
    this.object.active = null
    this.object.previous = null
  }

  private handleCloseCard() {
    const hotspot = this.hotspots.active || this.hotspots.previous

    if (hotspot) {
      const material = hotspot.material as THREE.MeshStandardMaterial
      material.color = this.defaultColor
    }

    this.resetObjectSelected()
  }

  private handleTouchStart(event: TouchEvent) {
    const { clientX, clientY } = event.changedTouches[0]
    const { renderer } = this.experience

    const rect = renderer.instance.domElement.getBoundingClientRect()
    const x = ((clientX - rect.left) / rect.width) * 2 - 1
    const y = -((clientY - rect.top) / rect.height) * 2 + 1

    this.raycaster.setFromCamera({ x, y }, this.experience.camera.instance)
    const intersects = this.raycaster.intersectObjects(this.objects, true)

    if (intersects.length) {
      const { object } = intersects[0]

      const glowMat = this.glowMats.find((m) => m.name === object.name)
      if (glowMat && glowMat.opacity > 0) {
        this.experience.world.body.UpdateSelectedHotspot(object.name)

        /**
         * Update area: active and previous ones
         */
        switch (true) {
          case !this.object.active:
            this.object.active = object as THREE.Mesh
            break
          case this.object.active?.name !== object.name:
            this.object.previous = this.object.active
            this.object.active = object as THREE.Mesh
            break
          case this.object.previous?.name !== object.name:
            this.object.previous = object as THREE.Mesh
            this.object.active = null
            break
        }
        // this.selected = Number(object.name.substring(object.name.length, object.name.length - 1))

        this.updateHotspots()
      }
    }
  }

  private createClickableArea() {
    const planeGeometry = new THREE.PlaneGeometry(1, 1)
    const planeMaterial = new THREE.MeshBasicMaterial({
      // color: 0xffff00,
      transparent: true,
      opacity: 0,
      depthWrite: false,
    })
    this.rect = new THREE.Mesh(planeGeometry, planeMaterial)
    this.rect.scale.multiplyScalar(0.32)
  }

  private addClickableArea(name: string, position: THREE.Vector3) {
    const area = this.rect.clone()
    area.position.copy(position)
    area.position.z += 0.001
    area.name = name

    this.hotspotGroup.add(area)
    this.objects.push(area)
  }

  private createInnerPoint() {
    this.innerPoint = this.parentPoint.clone()
    this.innerPoint.material = new THREE.MeshStandardMaterial({
      // color: Color.PRIMARY,
      color: new THREE.Color(0x808080),
      transparent: true,
      opacity: 0,
      depthWrite: false,
    })
    this.innerPoint.scale.multiplyScalar(0.5)
    this.innerPoint.position.z += 0.001;
    this.innerPoint.renderOrder = 4;
    this.innerMats.push(this.innerPoint.material as THREE.MeshStandardMaterial)
    this.hotspotGroup.add(this.innerPoint)
    this.objects.push(this.innerPoint)
  }
  private createGlowPoint(name: string) {
    this.glowPoint = this.parentPoint.clone()

    this.glowPoint.material = new THREE.MeshStandardMaterial({
      // color: Color.PRIMARY,
      color: new THREE.Color('#000000'),
      map: new THREE.TextureLoader().load(glowSrc),
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0,
      depthWrite: false,
      depthTest: true,
    })
    this.glowPoint.material.name = name
    this.glowPoint.name = name
    this.glowPoint.scale.multiplyScalar(2.5)
    this.glowPoint.position.z -= 0.001
    this.glowPoint.renderOrder = 2
    this.glowMats.push(this.glowPoint.material as THREE.MeshStandardMaterial)
    this.hotspotGroup.add(this.glowPoint)
    this.objects.push(this.glowPoint)
  }

  private createHand() {
    this.hand = this.parentPoint.clone()

    this.hand.material = new THREE.MeshStandardMaterial({
      // color: Color.PRIMARY,
      map: new THREE.TextureLoader().load(handSrc),
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0,
      depthWrite: false,
      depthTest: true,
    })
    this.hand.scale.multiplyScalar(3.5)
    this.hand.position.z += 0.002
    this.hand.rotation.z += (Math.PI / 4) * 3
    this.hand.renderOrder = 5
    this.handMats.push(this.hand.material as THREE.MeshStandardMaterial)
    this.hotspotGroup.add(this.hand)
    this.objects.push(this.hand)
  }
  private createPoint({ x, y }: THREE.Vector2, name: string) {
    const material = new THREE.MeshStandardMaterial({
      // color: Color.SECONDARY,
      color: Color.SECONDARY,
      transparent: true,
      opacity: 0,
      depthWrite: false,
    })

    this.parentPoint = new THREE.Mesh(this.geometry, material)
    this.parentPoint.name = name
    this.parentPoint.renderOrder = 3
    this.parentPoint.scale.multiplyScalar(0.009)
    this.parentPoint.position.set(x, y, this.zOffset)

    this.parentMats.push(material as THREE.MeshStandardMaterial)
    this.hotspotGroup.add(this.parentPoint)
    this.objects.push(this.parentPoint)

    this.createInnerPoint()
    this.createGlowPoint(name)
    this.createHand()
    this.addClickableArea(name, this.innerPoint.position)
  }

  public TurnInnerHotspotsGrey() {
    for (let i = 0; i < 3; i++) {
      gsap.to(this.innerMats[i].color, {
        r: 0.5,
        g: 0.5,
        b: 0.5,
        duration: 0.1,
        delay: 0,
        ease: 'power3.out',
      })
    }
  }

  public TurnInnerHotspotGreen(num: number) {
    gsap.to(this.innerMats[num].color, {
      r: 0,
      g: 1,
      b: 1,
      duration: 1,
      delay: 0,
      ease: 'power3.out',
    })
  }



  public createPoints(positions: THREE.Vector2[]) {
    positions.forEach((position, index) =>
      this.createPoint(position, `hotspot-${index}`)
    )

    this.revealDots()
  }

  public moveHotspots(index: number) {
    const hotspotPositions = [
      [0.13, 3.2],
      [0.16, 3.6],
      [0.08, 4.58],
    ]

    const hotspotLastTabPositions = [
      [0.17, 3.6],
      [0.16, 4.0],
      [0.4, 3.2],
    ]

    this.hotspotGroup.children.forEach(function (item) {
      if (index === 2) {
        for (let i = 0; i < 4; i++) {
          if (item.name === 'hotspot-' + i) {
            item.position.set(
              hotspotLastTabPositions[i][0],
              hotspotLastTabPositions[i][1],
              item.position.z
            )
          }
        }
      } else {
        for (let i = 0; i < 4; i++) {
          if (item.name === 'hotspot-' + i) {
            item.position.set(
              hotspotPositions[i][0],
              hotspotPositions[i][1],
              item.position.z
            )
          }
        }
      }
    })
  }

  public hide() {
    const mats = [
      ...this.parentMats,
      ...this.innerMats,
      ...this.glowMats,
      ...this.handMats,
    ]
    mats.forEach((mat) => {
      mat.opacity = 0
    })
  }

  private revealDots() {
    const tl = gsap.timeline()

    tl.to(
      [
        this.parentMats[0],
        this.innerMats[0],
        this.glowMats[0],
        this.handMats[0],
      ],
      {
        depthWrite: true,
        opacity: 1,
        duration: 1.5,
        ease: 'expo.out',
      }
    )

    const mat0 = this.innerMats[0]
    gsap.to(mat0.color, {
      r: 0,
      g: 1,
      b: 1,
      duration: 1,
      delay: 1.5,
      ease: 'power3.out',
    })
  }

  private updateHotspots() {
    /**
     * Find the active and previous hotspots
     */
    this.hotspots = this.hotspotGroup.children.reduce(
      (result: any, item: any, index: number) => {
        if (!result.active && item.name === this.object?.active?.name) {
          result.active = item
        }

        if (
          this.object?.previous &&
          !result.previous &&
          item.name === this.object?.previous?.name
        ) {
          result.previous = item
        }

        return result
      },
      {
        active: null,
        previous: null,
      }
    )

    /**
     * Update active hotspot
     */
    const currentMaterial = this.hotspots.active
      ?.material as THREE.MeshStandardMaterial

    if (currentMaterial) {
      currentMaterial.color = this.selectedColor
      EventEmitter.emit('point.selected', this.hotspots.active?.name)
    }

    /**
     * Update previous hotspot
     */
    const previousMaterial = this.hotspots.previous
      ?.material as THREE.MeshStandardMaterial

    if (previousMaterial) {
      previousMaterial.color = this.defaultColor
    }

    if (!this.hotspots?.active && this.hotspots?.previous) {
      EventEmitter.emit('hotspot.close', -1)
    }
  }

  public destroy() {
    window.removeEventListener('touchstart', this.handleTouchStart)
  }
}

export default Hotspot
