import React, { useEffect, useState } from "react";

import NavigationManager from "../../../../experience/Utils/NavigationManager";

import Navigation from "./components/Navigation";
import PulsingRing from "./components/PulsingRing";
import Card from "./components/Card";
import TextInfo from "./components/TextInfo";

import useHotspots from "../../../../config/hooks/useHotpspots";
import { eventsNames } from "./components/Card/data";
import { useMatomo } from "@datapunt/matomo-tracker-react";

/**
 * Dialog showing on click on a hotspot
 */
const cards = ["point-0-text", "point-1-text", "point-2-text"];

const isSelected = (value: number): boolean => value !== -1;

const GUI = () => {
  const [menu, setMenu] = useState(0);
  const { selected, onChangeMenuUpdateHotspot } = useHotspots(menu);
  const { trackEvent } = useMatomo();

  const handleNav = (index: number) => {
    if (index !== menu) {
      onChangeMenuUpdateHotspot();
      setMenu(index);
      NavigationManager.update(index);
    }
  };

  useEffect(() => {
    if (isSelected(selected))
      trackEvent({
        category: "hotspot",
        action: "tap event",
        name: eventsNames[menu][selected],
      });
  }, [menu, selected]);

  return (
    <div id="gui">
      <PulsingRing />

      {cards.map((id, index) => (
        <Card key={id} {...{ id, index, selected, menu }} />
      ))}

      <TextInfo {...{ menu, selected }} />

      <Navigation {...{ menu, handleNav }} />
    </div>
  );
};

export default React.memo(GUI);
