import { useState, useEffect, useCallback } from 'react'
import EventEmitter from '../../experience/Utils/EventEmitter'

const useHotspots = (menu: number) => {
  const [selected, setSelected] = useState(-1)

  const handleSelection = useCallback((name: string) => {
    const index = name.slice(-1)
    setSelected(Number(index))
  }, [])

  const handleClose = useCallback(() => {
    setSelected(-1)
  }, [])

  const onChangeMenuUpdateHotspot = () => {
    if (selected >= 0) {
      EventEmitter.emit('hotspot.close', -1)
    }
  }

  useEffect(() => setSelected(-1), [menu])

  useEffect(() => {
    EventEmitter.on('point.selected', handleSelection)
    EventEmitter.on('hotspot.close', handleClose)
  }, [handleClose, handleSelection])

  return { selected, onChangeMenuUpdateHotspot }
}

export default useHotspots
