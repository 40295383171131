import * as THREE from 'three'
import fragment from './fragment'

import vertex from './vertex'

export const xRayShaderMaterial = new THREE.ShaderMaterial({
  uniforms: {
    uPower: { value: 2.0 },
    uOpacity: { value: 0.0 },
    uGlowColor: { value: new THREE.Color('#266bb9') },
  },
  vertexShader: vertex,
  fragmentShader: fragment,
  side: THREE.DoubleSide,
  blending: THREE.AdditiveBlending,
  transparent: true,
  depthWrite: false,
})
