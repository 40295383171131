import { useRef, useEffect } from 'react'
import { useParallax } from '../../config/hooks/useParallax'

import Experience from '../../experience'
import GUI from './components/GUI'

import { Container } from './styles'

const Scene = () => {
  const targetRef = useRef<HTMLDivElement>(null)
  let experience = useRef<Experience>()

  useParallax()

  useEffect(() => {
    if (targetRef?.current) {
      experience.current = new Experience({
        targetElement: targetRef.current,
      })
    }

    return () => experience.current?.stop()
  }, [targetRef])

  return (
    <>
      <Container id="experience" ref={targetRef}></Container>
      <GUI />
    </>
  )
}

export default Scene
