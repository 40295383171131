import * as THREE from 'three'
import * as ZapparThree from '@zappar/zappar-threejs-dev'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js'

import Experience from '.'

// import { Pane } from 'tweakpane'
// const pane = new Pane({ title: 'renderer' })
export default class Renderer {
  private experience: Experience
  private cameraEffectComposer!: ZapparThree.PostProcessing.CameraEffectComposer

  public instance!: THREE.WebGLRenderer
  public effectComposer!: EffectComposer
  public outlinePass!: OutlinePass

  constructor() {
    this.experience = new Experience()

    this.removeDomElement()
    this.setInstance()
    this.setCameraPass()
    // this.shaderPass()
  }

  private shaderPass() {
    const { scene, camera } = this.experience
    const {
      config: { screen },
    } = this.experience
    /**
     * Post processing
     */

    // Build the render target depending of the pixel ratio and web browser capability with WebGL2
    let RenderTargetClass = null

    if (
      this.instance.getPixelRatio() === 1 &&
      this.instance.capabilities.isWebGL2
    ) {
      RenderTargetClass = THREE.WebGLMultisampleRenderTarget
      console.log('Using WebGLMultisampleRenderTarget')
    } else {
      RenderTargetClass = THREE.WebGLRenderTarget
      console.log('Using WebGLRenderTarget')
    }

    const renderTarget = new RenderTargetClass(800, 600, {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      format: THREE.RGBAFormat,
      encoding: THREE.sRGBEncoding,
    })

    this.effectComposer = new EffectComposer(this.instance, renderTarget)
    this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    this.effectComposer.setSize(screen.width, screen.height)

    const renderPass = new RenderPass(scene, camera.instance)
    this.effectComposer.addPass(renderPass)

    this.outlinePass = new OutlinePass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      scene,
      camera.instance
    )
    this.effectComposer.addPass(this.outlinePass)
  }

  private setCameraPass() {
    this.cameraEffectComposer =
      new ZapparThree.PostProcessing.CameraEffectComposer(
        this.experience.camera.instance,
        this.instance
      )

    // const params = {
    //   strength: 1.1,
    //   directions: 16.0,
    //   quality: 4,
    // }

    const blurPass = new ZapparThree.PostProcessing.CameraPass.BlurPass()
    blurPass.setStrength(0.5)

    this.cameraEffectComposer.addPass(blurPass)

    this.experience.scene.background = this.cameraEffectComposer.texture

    // const folder = pane.addFolder({
    //   title: 'Blur',
    //   expanded: false,
    // })
    // folder
    //   .addInput(params, 'strength', {
    //     min: 0,
    //     max: 10,
    //     step: 0.1,
    //   })
    //   .on('change', ({ value }) => {
    //     blurPass.setStrength(value)
    //   })

    // folder
    //   .addInput(params, 'directions', {
    //     min: 0,
    //     max: 40,
    //     step: 1,
    //   })
    //   .on('change', ({ value }) => {
    //     blurPass.setDirections(value)
    //   })

    // folder
    //   .addInput(params, 'quality', {
    //     min: 0,
    //     max: 20,
    //     step: 1,
    //   })
    //   .on('change', ({ value }) => {
    //     blurPass.setQuality(value)
    //   })
  }

  private removeDomElement() {
    const canvas = document.querySelector('canvas')
    if (canvas) document.getElementById('experience')?.removeChild(canvas)
  }

  private setInstance() {
    this.instance = new THREE.WebGLRenderer({ antialias: true })
    this.instance.setSize(
      this.experience.config.screen.width,
      this.experience.config.screen.height
    )

    this.instance.setPixelRatio(this.experience.config.screen.pixelRatio)
    this.instance.physicallyCorrectLights = true
    this.instance.outputEncoding = THREE.sRGBEncoding

    this.instance.toneMapping = THREE.ACESFilmicToneMapping
    this.instance.toneMappingExposure = 1

    // const f2 = pane.addFolder({ title: 'realistic', expanded: false })
    // f2.addInput(this.instance, 'toneMappingExposure', {
    //   min: 0,
    //   max: 10,
    //   step: 0.00001,
    // })

    // this.instance.shadowMap.enabled = true
    // this.instance.shadowMap.type = THREE.PCFSoftShadowMap
  }

  public resize() {
    this.instance.setSize(
      this.experience.config.screen.width,
      this.experience.config.screen.height
    )
    this.instance.setPixelRatio(this.experience.config.screen.pixelRatio)
  }

  public update() {
    this.cameraEffectComposer?.render()
    this.instance.render(this.experience.scene, this.experience.camera.instance)
    // this.effectComposer.render()
  }
}
