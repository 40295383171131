import styled from "styled-components";

type ZeePermissionsProps = {
  active: boolean;
};

const ZeePermissions = styled.div<ZeePermissionsProps>`
  font-family: sans-serif;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100%);
  display: ${({ active }) => (active ? "block" : "none")};
  z-index: 10000;
`;

const Wrapper = styled.div`
  position: relative;
  margin: auto;
  width: calc(90% - 30px);
  max-width: 300px;
  padding: 15px;
  background-color: white;
  box-shadow: 0 0px 15px 0px #0000003d;
  font-size: 0.85rem;

  & > span {
    font-weight: 800;
    font-size: 1.2rem;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 20px 0;
  margin-bottom: 0;
`;

const buttonColour = "#1151c7";

const ButtonAccept = styled.button`
  padding: 12px 30px;
  outline: none;
  background-color: ${buttonColour};
  border: unset;
  font-weight: 800;
  border-radius: -48px;
  font-size: 0.9rem;
  border: solid 2px ${buttonColour};
  color: white;
`;

const ButtonReject = styled(ButtonAccept)`
  color: #1151c7;
  background-color: white;
`;

type CookiesBannerProps = ZeePermissionsProps & {
  handleConsent: (val: boolean) => void;
};

export default function CookiesBanner({
  active,
  handleConsent,
}: CookiesBannerProps) {
  return (
    <ZeePermissions active={active}>
      <Wrapper>
        <span>Hinweis</span>
        <br />
        <br />
        Wir verwenden Cookies, um Ihre Erfahrung zu verbessern und unseren
        Datenverkehr zu analysieren. Indem Sie auf "Akzeptieren" klicken,
        stimmen Sie unserer Verwendung von Cookies zu.
        <a href="https://www.zappar.com/privacy">Datenschutz-Bestimmungen</a>
        <ButtonsWrapper>
          <ButtonReject onClick={() => handleConsent(false)}>
            Ablehnen
          </ButtonReject>
          <ButtonAccept onClick={() => handleConsent(true)}>
            Akzeptieren
          </ButtonAccept>
        </ButtonsWrapper>
      </Wrapper>
    </ZeePermissions>
  );
}

/**
 *  <style>
      #zee-permissions {
        font-family: sans-serif;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: calc(100%);
        display: none;
        z-index: 10000;
      }

      #zee-permissions > div {
        position: relative;
        margin: auto;
        width: calc(90% - 30px);
        max-width: 300px;
        padding: 15px;
        background-color: white;
        box-shadow: 0 0px 15px 0px #0000003d;
        font-size: 0.85rem;
      }
      #zee-permissions > div > span {
        font-weight: 800;
        font-size: 1.2rem;
      }
      #zee-permissions > div > div {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 20px 0;
        margin-bottom: 0;
      }
      :root {
        --button-color: #1151c7;
      }
      #zee-permissions > div > div > button {
        padding: 12px 30px;
        outline: none;
        background-color: var(--button-color);
        border: unset;
        font-weight: 800;
        border-radius: -48px;
        font-size: 0.9rem;
        border: solid 2px var(--button-color);
        color: white;
      }
 */
