import { useState } from 'react'

import { ZapparPermissionRquest } from '../../utils/zappar'

const usePermission = () => {
  const [granted, setGranted]  = useState<boolean | null>(null)

  const handlePermission = () => ZapparPermissionRquest(setGranted)

  return { granted, handlePermission }
}

export default usePermission
