import { useState, useEffect, useRef, useCallback } from 'react'
import { useStopwatch } from 'react-use-precision-timer'

import EventEmitter from '../../experience/Utils/EventEmitter'

const usePrecisionTimer = () => {
  const startTime = '00:00:00'
  const endTime = '00:30:00'

  const stopwatch = useStopwatch()
  let timeoutRef = useRef<NodeJS.Timeout>()
  const timeRef = useRef(startTime)
  const [time, setTime] = useState(startTime)

  const running = stopwatch.isRunning()
  const paused = stopwatch.isPaused()
  const stopped = stopwatch.isStopped()
  const done = time === endTime

  const start = () => stopwatch.start()

  const stop = useCallback(() => {
    if (timeoutRef.current) window.clearTimeout(timeoutRef.current)
    stopwatch.stop()
    setTime(startTime)
  }, [stopwatch])

  const resume = () => stopwatch.resume()

  const reset = () => {
    stopwatch.stop()
    stopwatch.start()
  }

  useEffect(() => {
    const frameRate = stopwatch.isRunning() ? 10 : 0

    const timeUpdate = () => {
      const date = new Date(stopwatch.getElapsedRunningTime() + frameRate)

      const padZero = (time: any) => {
        return time < 10 ? '0' + time : '' + time
      }

      const ms = date.getMilliseconds()
      const s = date.getSeconds()
      const m = date.getMinutes()

      if (s === 30) {
        timeRef.current = endTime

        const domBtn = document.getElementById(
          'stopwatch-btn'
        ) as HTMLButtonElement

        domBtn.innerText = 'Starten'

        stopwatch.pause()

        if (timeoutRef.current) window.clearTimeout(timeoutRef.current)
      } else {
        timeRef.current = `${m < 10 ? '0' + m : m}:${
          s < 10 ? '0' + s : s
        }:${padZero('' + ((ms / 10) | 0))}`
      }

      if (stopwatch.isPaused()) {
        setTime(endTime)
      } else {
        setTime(timeRef.current)
      }
    }

    if (stopwatch.isStarted()) {
      timeoutRef.current = setTimeout(() => {
        timeUpdate()
      }, frameRate)
    }
  }, [stopwatch])

  useEffect(() => {
    EventEmitter.on('stopwatch.stop', () => stop())
  }, [stop])

  return {
    time,
    start,
    stop,
    resume,
    reset,
    running,
    paused,
    stopped,
    done,
  }
}

export default usePrecisionTimer
