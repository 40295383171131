import { useEffect, useState, useCallback } from 'react'

import EventEmitter from '../../../../../../experience/Utils/EventEmitter'

import Menu1 from './components/Menu1'
import Menu2 from './components/Menu2'
import Menu3 from './components/Menu3'

import { Tabs, Tab } from './styles'

type Props = {
  handleNav: (newIndex: number) => void
  menu: number
}

const Navigation = ({ handleNav, menu }: Props) => {
  const [disabled, set] = useState(true)

  const enableNavigation = useCallback(() => set(!disabled), [disabled])

  useEffect(
    () => EventEmitter.on('navigation', enableNavigation),
    [enableNavigation]
  )

  return (
    <>
      <Tabs>
        <div className="menus">
          <Tab
            onClick={() => handleNav(0)}
            active={menu === 0}
            {...{ disabled }}
          >
            <Menu1 active={menu === 0} />
          </Tab>

          <Tab
            onClick={() => handleNav(1)}
            active={menu === 1}
            {...{ disabled }}
          >
            <Menu2 active={menu === 1} />
          </Tab>

          <Tab
            onClick={() => handleNav(2)}
            active={menu === 2}
            {...{ disabled }}
          >
            <Menu3 active={menu === 2} />
          </Tab>
        </div>

        <div className="text">
          <span>Erfahren Sie mehr unter</span>
          <br></br>
          <span className="url">www.herzstolpern.de</span>
        </div>
      </Tabs>

      {/* <>
        <Content active={active === 0}><h1>Content 1</h1></Content>

        <Content active={active === 1}><h1>Content 2</h1></Content>

        <Content active={active === 2}><h1>Content 3</h1></Content>
      </> */}
    </>
  )
}

export default Navigation
