import { useEffect, useState, useCallback, useRef } from 'react'

import * as THREE from 'three'

import EventEmitter from '../../experience/Utils/EventEmitter'
import useDeviceOrientation from './useDeviceOrientation'

/**
 * Manage parallax effect on the group model
 */
export const useParallax = () => {
  let euler = useRef<THREE.Euler>(new THREE.Euler())
  let timerRef = useRef<NodeJS.Timeout | null>(null)

  let [group, setGroup] = useState<THREE.Group | null>(null)

  const device = useDeviceOrientation()

  const handleModel = useCallback((group: THREE.Group) => setGroup(group), [])

  useEffect(() => {
    EventEmitter.on('model.loaded', (group: THREE.Group) => {
      handleModel(group)
      EventEmitter.removeListener('model.loaded', handleModel)
    })
  }, [handleModel])

  useEffect(() => {
    const handleRotationUpdate = () => {
      if (group) {
        const { orientation } = device

        const rY = orientation.gamma * 0.01
        const gammaRad = rY
        const maxRad = 0.436332 // 25 degrees
        const shoudUpdate = gammaRad <= maxRad * 2 && gammaRad >= -maxRad

        if (shoudUpdate) {
          euler.current.copy(group.rotation)

          euler.current.y = rY
          // euler.current.x = rY * 0.1
          // euler.current.z = rY * 0.1

          group.rotation.copy(euler.current)
        }
      }
    }

    timerRef.current = setTimeout(() => handleRotationUpdate(), 10)
  }, [group, device])
}
