import * as THREE from 'three'
import Experience from '..'

import { gsap } from 'gsap'

class Thrombus {
  private experience = new Experience()

  private isRoutesDefined = false

  private container?: HTMLCanvasElement

  private movingPoint!: {
    routes: THREE.Vector3[]
    element: HTMLDivElement
  }
  private ringPulse!: HTMLDivElement

  private tl = gsap.timeline()

  private timeout!: NodeJS.Timeout

  private looping!: boolean

  constructor() {
    this.container = document.querySelector('canvas') as HTMLCanvasElement
    this.ringPulse = document.getElementById('ring-pulse') as HTMLDivElement
    this.looping = false;
  }

  public setRoutes(routes: THREE.Vector3[]) {
    const element = document.getElementById('ring') as HTMLDivElement

    const start = routes[0]

    this.movingPoint = {
      element,
      routes,
    }

    const sizes = {
      width: this.container?.offsetWidth || 0,
      height: this.container?.offsetHeight || 0,
    }

    const startPosition = start.clone()
    startPosition.project(this.experience.camera.instance)

    const translateX = startPosition.x * sizes.width * 0.5
    const translateY = -startPosition.y * sizes.height * 0.5

    element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
  }

  public repeat() {
    this.tl.play(0)
  }

  public setLoopingFalse(){
    this.looping = false;
  }

  public continue() {
    this.tl.play()
  }

  public stop() {
    this.movingPoint.element.style.opacity = '0'
    clearTimeout(this.timeout)
    if (this.tl.isActive()) {
      this.tl.pause()
    }
  }

  private pulseAnimationManagement(state = 'paused') {
    const shouldPause = state === 'paused'
    this.ringPulse.style.animationPlayState = state
    this.ringPulse.style.opacity = shouldPause ? '0' : '1'
    this.ringPulse.style.display = shouldPause ? 'none' : 'block'
  }

  public start() {
    switch (this.isRoutesDefined) {
      case true:
        this.repeat()
        break
      case false:
        const sizes = {
          width: this.container?.offsetWidth || 0,
          height: this.container?.offsetHeight || 0,
        }

        const { routes, element } = this.movingPoint

        const positions = routes.map((route, index) => {
          const position = route.clone()
          position.project(this.experience.camera.instance)

          const translateX = position.x * sizes.width * 0.5
          const translateY = -position.y * sizes.height * 0.5

          return {
            transform: `translateX(${translateX}px) translateY(${translateY}px)`,
          }
        })

        const p1 = positions[1]
        const p2 = positions[2]
        const p3 = positions[3]
        const p4 = positions[4]
        const p5 = positions[5]
        const p6 = positions[6]
        const p7 = positions[7]

        this.tl.to(element, {
          opacity: 1,
          duration: 0.5,
          ease: 'power3.in',
          onStart: () => this.pulseAnimationManagement('running'),
        })

        this.tl.timeScale(0.45)

        this.tl
          .to(element, {
            transform: p1.transform,
            ease: 'power0',
            duration: 0.5,
          })
          .to(element, {
            transform: p2.transform,
            ease: 'power0',
            duration: 0.25,
          })
          .to(element, {
            transform: p3.transform,
            ease: 'power0',
          })
          .to(element, {
            transform: p4.transform,
            ease: 'power0',
            duration: 1,
          })
          .to(element, {
            transform: p5.transform,
            ease: 'power0',
            duration: 0.5,
          })
          .to(element, {
            transform: p6.transform,
            ease: 'power0',
            duration: 0.6,
          })
          .to(element, {
            transform: p7.transform,
            ease: 'power0',
            duration: 0.5,
            onComplete: () => {
              if(!this.looping){
                this.tl.pause()
              }
            },
          })
          .to('.ringring', {
            animation: 'none',
            opacity: 0,
            duration: 0.5,
            delay: -0.5,
            ease: 'expo.out',
          })
          .to('.ring-circle', {
            width: '0px',
            height: '0px',
            delay: 0.3,
            ease: 'power3.out',
            // backgroundColor: '#701c14f7',
            // boxShadow:
            //   '0 0 0.85em 0.35em #701c14, 0 0 0.9em 0.8em #a7281c, 0 0 1.5em 1.5em #bb3c2f',
            transform: 'scale(1.25,1.25) translateX(8px)',
            onStart: () => this.pulseAnimationManagement('paused'),
          })
          .to(element, {
            opacity: 0,
            duration: 1,
            ease: 'power3.out',
            onComplete: () => {
              this.looping = true;
              this.timeout = setTimeout(() => this.repeat(), 1000)
            },
          })

        this.isRoutesDefined = true
        break
      default:
    }
  }
}

export default Thrombus
