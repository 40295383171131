/**
 * Thrombus animation
 */
const PulsingRing = () => {
  return (
    <div id="ring" className="ring-container">
      <div id="ring-pulse" className="ringring"></div>
      <div className="ring-circle"></div>
    </div>
  )
}

export default PulsingRing
