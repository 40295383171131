import Scene from "../components/Scene";

import { AppLayout } from "./styles";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Welcome from "./components/Welcome";
import useProgress from "../config/hooks/useProgress";
import usePermission from "../config/hooks/usePermission";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import CookiesBanner from "./components/CookiesBanner";
import useCookiesPermissions from "../config/hooks/useCookiesConsent";
import { useEffect } from "react";

const App = () => {
  const { granted, handlePermission } = usePermission();
  const { ready, progress } = useProgress(granted);
  const { enableLinkTracking, pushInstruction } = useMatomo();
  const { bannerConsent, cookiesConsent, setCookiesConsent } =
    useCookiesPermissions();

  enableLinkTracking(); // this will enable the link tracking option

  useEffect(() => {
    if (cookiesConsent === true) pushInstruction("setCookieConsentGiven");
  }, [cookiesConsent]);

  return (
    <>
      <AppLayout {...{ granted }}>
        <div className="page-header">
          <Header />
        </div>

        <div className="page-content">
          {granted && <Scene />}
          <Welcome {...{ handlePermission, granted, progress, ready }} />
        </div>

        <div className="page-footer">
          <Footer />
        </div>
      </AppLayout>
      <CookiesBanner
        active={!Boolean(bannerConsent)}
        handleConsent={setCookiesConsent}
      />
    </>
  );
};
export default App;
