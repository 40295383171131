import * as THREE from 'three'

// import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper'

import Experience from '.'

import Background from './Background'
import Body from './Body'

// import { Pane } from 'tweakpane'

export default class World {
  static instance: World

  private experience = new Experience()

  public body!: Body
  public background!: Background

  constructor() {
    if (World.instance) {
      return World.instance
    }
    World.instance = this

    // this.setLight()
    this.setLight2()
    this.setBackground()
    this.setBody()
  }

  public setView(index: number) {
    this.body.viewUpdate(index)
  }

  public setSelected(selected: string) {
    this.body.UpdateSelectedHotspot(selected)
  }

  private setLight2() {
    const { scene } = this.experience

    // const pane = new Pane()

    const ambientLight = new THREE.AmbientLight(0xffffff, 1)
    scene.add(ambientLight)

    /**
     * 1
     */
    // const rimLight = new THREE.RectAreaLight(0xffffff, 7.609, 4, 45)
    // rimLight.position.set(-1.087, -5.797, -7.609)

    const rimLight = new THREE.RectAreaLight(0xffffff, 9, 10, 45)
    rimLight.position.set(-1.087, -5.797, -10.0)
    rimLight.visible = true
    rimLight.rotation.y = 3

    // const rimLight = new THREE.RectAreaLight(0xffffff, 5.745, 19, 19)
    // rimLight.position.set(-3.561, 16.977, -19.524)
    // rimLight.rotation.y = 3
    // const rimHelper = new RectAreaLightHelper(rimLight, 'orange')
    scene.add(rimLight)

    // const f1 = pane.addFolder({ title: 'Rim light' })
    // f1.addInput(rimLight.rotation, 'y', { step: 0.001, view: 'slider' })
    // f1.addInput(rimLight, 'intensity', { min: 0, max: 50, step: 0.001 })

    // f1.addInput(rimLight, 'visible')

    // f1.addInput(rimLight, 'width', { min: 0, max: 100, step: 1 })
    // f1.addInput(rimLight, 'height', { min: 0, max: 100, step: 1 })

    // f1.addInput(rimLight.position, 'x', { min: -50, max: 50, step: 0.001 })
    // f1.addInput(rimLight.position, 'y', { min: -50, max: 50, step: 0.001 })
    // f1.addInput(rimLight.position, 'z', { min: -50, max: 50, step: 0.001 })
    // f1.addInput(rimHelper, 'visible')

    /**
     * 2
     */
    // const rimLight2 = new THREE.RectAreaLight(0xffffff, 0, 6, 45)
    // rimLight2.position.set(-1.087, -5.797, -7.609)
    // rimLight2.visible = true
    // rimLight2.rotation.y = 3

    // const rimHelper2 = new RectAreaLightHelper(rimLight2, 'red')
    // scene.add(rimLight2, rimHelper2)

    // const f2 = pane.addFolder({ title: 'Rim light 2' })

    // f2.addInput(rimLight2.rotation, 'y', { step: 0.001, view: 'slider' })
    // f2.addInput(rimLight2, 'intensity', { min: 0, max: 50, step: 0.001 })

    // f2.addInput(rimLight2, 'visible')

    // f2.addInput(rimLight2, 'width', { min: 0, max: 100, step: 1 })
    // f2.addInput(rimLight2, 'height', { min: 0, max: 100, step: 1 })

    // f2.addInput(rimLight2.position, 'x', { min: -50, max: 50, step: 0.001 })
    // f2.addInput(rimLight2.position, 'y', { min: -50, max: 50, step: 0.001 })
    // f2.addInput(rimLight2.position, 'z', { min: -50, max: 50, step: 0.001 })

    /**
     * 3
     */
    // const rimLight3 = new THREE.RectAreaLight(0xffffff, 0, 6, 45)
    // rimLight2.position.set(-1.087, -5.797, -7.609)
    // rimLight2.visible = true
    // rimLight2.rotation.y = 3

    // const rimHelper3 = new RectAreaLightHelper(rimLight3, 'yellow')
    // scene.add(rimLight3, rimHelper3)

    // const f3 = pane.addFolder({ title: 'Rim light 3' })

    // f3.addInput(rimLight3.rotation, 'y', { step: 0.001, view: 'slider' })
    // f3.addInput(rimLight3, 'intensity', { min: 0, max: 50, step: 0.001 })

    // f3.addInput(rimLight3, 'visible')

    // f3.addInput(rimLight3, 'width', { min: 0, max: 100, step: 1 })
    // f3.addInput(rimLight3, 'height', { min: 0, max: 100, step: 1 })

    // f3.addInput(rimLight3.position, 'x', {
    //   min: -50,
    //   max: 50,
    //   step: 0.001,
    // })
    // f3.addInput(rimLight3.position, 'y', {
    //   min: -50,
    //   max: 50,
    //   step: 0.001,
    // })
    // f3.addInput(rimLight3.position, 'z', {
    //   min: -50,
    //   max: 50,
    //   step: 0.001,
    // })

    /**
     *
     */
    // const directionalLight = new THREE.DirectionalLight('#ffffff', 3.439)
    // directionalLight.position.set(0.0, 2.826, 4.457)

    const directionalLight = new THREE.DirectionalLight('#ffffff', 4.5)
    directionalLight.visible = true
    // // directionalLight.castShadow = true
    // // directionalLight.shadow.camera.far = 15
    // // directionalLight.shadow.mapSize.set(1024, 1024)
    // // directionalLight.shadow.normalBias = 0.05

    directionalLight.position.set(1.6, 1.77, 2.9)
    scene.add(directionalLight)

    // const f2 = pane.addFolder({ title: 'Directional Light', expanded: false })

    // pane.addInput(directionalLight, 'visible')
    // pane.addInput(directionalLight, 'intensity', {
    //   min: 0,
    //   max: 10,
    //   step: 0.001,
    // })

    // pane.addInput(directionalLight.position, 'x', {
    //   min: 0,
    //   max: 10,
    //   step: 0.001,
    // })
    // pane.addInput(directionalLight.position, 'y', {
    //   min: 0,
    //   max: 10,
    //   step: 0.001,
    // })
    // pane.addInput(directionalLight.position, 'z', {
    //   min: 0,
    //   max: 10,
    //   step: 0.001,
    // })

    // SpotLight
    // const spotLight = new THREE.SpotLight('#ffffff', 3)
    // spotLight.position.set(0.0, 5.6, 0.0)
    // spotLight.visible = true
    // scene.add(spotLight)

    // const f3 = pane.addFolder({ title: 'Spot Light' })
    // f3.addInput(spotLight, 'visible')
    // f3.addInput(spotLight, 'intensity', {
    //   min: 0,
    //   max: 10,
    //   step: 0.001,
    // })
    // f3.addInput(spotLight.position, 'x', {
    //   min: 0,
    //   max: 10,
    //   step: 0.001,
    // })
    // f3.addInput(spotLight.position, 'y', {
    //   min: 0,
    //   max: 10,
    //   step: 0.001,
    // })
    // f3.addInput(spotLight.position, 'z', {
    //   min: 0,
    //   max: 10,
    //   step: 0.001,
    // })

    // Point light
    // const light = new THREE.PointLight('#ffffff', 1.0)
    // light.position.set(-0.9, 1.5, -5.0)
    // const helper = new THREE.PointLightHelper(light, 0.12, 'red')
    // scene.add(light, helper)

    // directionalLight.shadow.normalBias = 0.05

    // const f3 = pane.addFolder({ title: 'Spot Light', expanded: false })
    // f3.addInput(light, 'visible')

    // f3.addInput(light, 'intensity', {
    //   min: 0,
    //   max: 20,
    //   step: 0.001,
    // })

    // f3.addInput(light.position, 'x', {
    //   min: -10,
    //   max: 10,
    //   step: 0.001,
    // })
    // f3.addInput(light.position, 'y', {
    //   min: -10,
    //   max: 10,
    //   step: 0.001,
    // })
    // f3.addInput(light.position, 'z', {
    //   min: -10,
    //   max: 10,
    //   step: 0.001,
    // })

    // OLD

    // const { scene } = this.experience

    // const rimLight = new THREE.RectAreaLight(0xffffff, 11.335, 13, 19)
    // rimLight.position.set(-3.561, 4.7, -11.553)
    // rimLight.visible = false
    // rimLight.rotation.y = 3

    // // const rimLight = new THREE.RectAreaLight(0xffffff, 5.745, 19, 19)
    // // rimLight.position.set(-3.561, 16.977, -19.524)
    // // rimLight.rotation.y = 3
    // // const rimHelper = new RectAreaLightHelper(rimLight, 'orange')
    // scene.add(rimLight)

    // const f1 = pane.addFolder({ title: 'Rim light', expanded: false })

    // f1.addInput(rimLight, 'rotation', { step: 0.001 })
    // f1.addInput(rimLight, 'intensity', { min: 0, max: 50, step: 0.001 })

    // f1.addInput(rimLight, 'visible')

    // f1.addInput(rimLight, 'width', { min: 0, max: 100, step: 1 })
    // f1.addInput(rimLight, 'height', { min: 0, max: 100, step: 1 })

    // f1.addInput(rimLight.position, 'x', { min: -50, max: 50, step: 0.001 })
    // f1.addInput(rimLight.position, 'y', { min: -50, max: 50, step: 0.001 })
    // f1.addInput(rimLight.position, 'z', { min: -50, max: 50, step: 0.001 })

    // // f1.addInput(rimHelper, 'visible')

    // const directionalLight = new THREE.DirectionalLight('#ffffff', 1.7)
    // directionalLight.visible = true
    // // directionalLight.castShadow = true
    // // directionalLight.shadow.camera.far = 15
    // // directionalLight.shadow.mapSize.set(1024, 1024)

    // directionalLight.position.set(0.05, 1.65, 0.9)
    // scene.add(directionalLight)

    // // directionalLight.shadow.normalBias = 0.05

    // const f2 = pane.addFolder({ title: 'Directional Light', expanded: false })

    // f2.addInput(directionalLight, 'visible')
    // f2.addInput(directionalLight, 'intensity', {
    //   min: 0,
    //   max: 10,
    //   step: 0.001,
    // })

    // f2.addInput(directionalLight.position, 'x', {
    //   min: 0,
    //   max: 10,
    //   step: 0.001,
    // })
    // f2.addInput(directionalLight.position, 'y', {
    //   min: 0,
    //   max: 10,
    //   step: 0.001,
    // })
    // f2.addInput(directionalLight.position, 'z', {
    //   min: 0,
    //   max: 10,
    //   step: 0.001,
    // })

    // // SpotLight
    // const light = new THREE.SpotLight('#ffffff', 4.5)
    // light.position.set(0.0, 5.6, 0.0)
    // light.visible = true
    // scene.add(light)

    // // Point light
    // // const light = new THREE.PointLight('#ffffff', 1.0)
    // // light.position.set(-0.9, 1.5, -5.0)
    // // const helper = new THREE.PointLightHelper(light, 0.12, 'red')
    // // scene.add(light, helper)

    // // directionalLight.shadow.normalBias = 0.05

    // const f3 = pane.addFolder({ title: 'Spot Light', expanded: false })
    // f3.addInput(light, 'visible')

    // f3.addInput(light, 'intensity', {
    //   min: 0,
    //   max: 20,
    //   step: 0.001,
    // })

    // f3.addInput(light.position, 'x', {
    //   min: -10,
    //   max: 10,
    //   step: 0.001,
    // })
    // f3.addInput(light.position, 'y', {
    //   min: -10,
    //   max: 10,
    //   step: 0.001,
    // })
    // f3.addInput(light.position, 'z', {
    //   min: -10,
    //   max: 10,
    //   step: 0.001,
    // })
  }

  private setLight() {
    const { scene } = this.experience
    // const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
    // const ambientLight = new THREE.AmbientLight(0x787575, 0.5)
    // scene.add(ambientLight)

    // // Rim light
    // /**
    //  * intensity: 18
    //  * width: 52
    //  * height: 54
    //  * x: -1.4 (-10)
    //  * y: 9.855
    //  * z: -19
    //  */
    const rimLight = new THREE.RectAreaLight(0xffffff, 11.335, 13, 19)
    rimLight.position.set(-3.561, 4.7, -11.553)
    rimLight.rotation.y = 3

    // const f1 = pane.addFolder({ title: 'Rim light' })
    // f1.addInput(rimLight.rotation, 'y', { step: 1 })
    // f1.addInput(rimLight, 'intensity', { min: 0, max: 50, step: 0.001 })

    // f1.addInput(rimLight, 'width', { min: 0, max: 100, step: 1 })
    // f1.addInput(rimLight, 'height', { min: 0, max: 100, step: 1 })

    // f1.addInput(rimLight.position, 'x', { min: -10, max: 10, step: 0.001 })
    // f1.addInput(rimLight.position, 'y', { min: -10, max: 10, step: 0.001 })
    // f1.addInput(rimLight.position, 'z', { min: -50, max: 10, step: 0.001 })

    // // const rimHelper = new RectAreaLightHelper(rimLight, 'orange')
    scene.add(rimLight)

    // const pointLight = new THREE.PointLight(0xffffff, 2)
    const pointLight = new THREE.PointLight(0xffffff, 0.9)

    // const f3 = pane.addFolder({ title: 'Light 1' })
    // const f4 = pane.addFolder({ title: 'Light 2' })

    // pointLight.position.x = -0.58
    // pointLight.position.y = 0.497
    // pointLight.position.z = 2
    pointLight.position.x = -0.58
    pointLight.position.y = 0.453
    pointLight.position.z = -3
    scene.add(pointLight)

    // f3.addInput(pointLight, 'intensity', {
    //   step: 0.001,
    //   max: 50,
    //   min: 0,
    // })
    // f3.addInput(pointLight.position, 'x', {
    //   step: 0.001,
    //   max: 5,
    //   min: -5,
    // })
    // f3.addInput(pointLight.position, 'y', {
    //   step: 0.001,
    //   max: 5,
    //   min: -5,
    // })
    // f3.addInput(pointLight.position, 'z', {
    //   step: 0.001,
    //   max: 5,
    //   min: -3,
    // })
    // const params = {
    //   color: '#ffffff',
    //   helper: true,
    // }
    // f3.addInput(params, 'color').on('change', ({ value }) => {
    //   pointLight.color = new THREE.Color(value)
    // })

    // const helper = new THREE.PointLightHelper(pointLight, 0.12, 'orange')
    // helper.visible = true
    // scene.add(helper)

    // f3.addInput(params, 'helper').on('change', ({ value }) => {
    //   helper.visible = value
    // })

    // --- POINT LIGHT 3
    // const pointLight2 = new THREE.PointLight(0xffffff, 1.5)

    // pointLight2.position.x = -0.952
    // pointLight2.position.y = -0.6
    // pointLight2.position.z = -2.8
    // scene.add(pointLight2)
    const pointLight2 = new THREE.PointLight(0xffffff, 2)

    pointLight2.position.x = -0.523
    pointLight2.position.y = -0.369
    pointLight2.position.z = -4.373
    scene.add(pointLight2)

    // f4.addInput(pointLight2, 'intensity', {
    //   step: 0.001,
    //   max: 50,
    //   min: 0,
    // })
    // f4.addInput(pointLight2.position, 'x', {
    //   step: 0.001,
    //   max: 5,
    //   min: -5,
    // })
    // f4.addInput(pointLight2.position, 'y', {
    //   step: 0.001,
    //   max: 5,
    //   min: -5,
    // })
    // f4.addInput(pointLight2.position, 'z', {
    //   step: 0.001,
    //   max: 5,
    //   min: -5,
    // })
    // const params2 = {
    //   color: '#ffffff',
    //   helper: true,
    // }
    // f4.addInput(params2, 'color').on('change', ({ value }) => {
    //   pointLight2.color = new THREE.Color(value)
    // })

    // const helper2 = new THREE.PointLightHelper(pointLight2, 0.12, 'pink')
    // helper2.visible = true
    // scene.add(helper2)

    // f4.addInput(params2, 'helper').on('change', ({ value }) => {
    //   helper2.visible = value
    // })
  }

  private setBackground() {
    this.background = new Background()
  }

  private setBody() {
    // this.body = new Body(pane)
    this.body = new Body(null)
  }

  resize() {}

  update() {
    this.body.update()
  }

  destroy() {}
}
