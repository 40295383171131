import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  overflow-y: auto;
  top: 0;
  right: 0;
       -webkit-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;
 -moz-hyphens: auto;


  // transition: opacity 0.2s;
  // -webkit-transition: opacity 0.2s;

  opacity: 0;
  z-index: -1;

  padding: 1.5em 0.5em;
  margin: 1em 0;

  // transition: opacity 0.3s;
  // pointer-events: none;
  // text-align: center;
  // text-align: justify;
  // text-justify: inter-word;

  width: 46%;
  height: fit-content;
  max-height: calc(100% - 156.8px - 0.5em);

  border: 1px solid #ffffff77;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 10px 0 0 10px;

  /* Close Button */

  [class*='close-'] {
    color: #777;
    font: 6.5vw arial, sans-serif;
    position: absolute;
    right: 0.5em;
    text-decoration: none;
    text-shadow: 0 1px 0 #fff;
    top: 0.25em;
  }

  .close-btn:after {
    content: '×'; /* UTF-8 symbol */
  }
  li{
    text-align: left;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  -moz-hyphens: auto;
  }
  p{
    text-align: left;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  -moz-hyphens: auto;



  h3,
  p,
  span,
  ul {
    margin: 0;
    padding: 0;

    text-align: center;

    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .separator {
    width: 80%;
    height: 2px;
    border: none; // 2px solid #ffffff77;

    background: #c7c7c7;
  }

  /* Title */

  h3 {
    font-family: PTSans-Bold;
    font-size: 5vw;
    color: #333333;
    letter-spacing: -0.08px;
  }

  /* Text */

  p,
  ul {
    font-family: PTSans-Regular;
    font-size: 4vw;
    color: #333333;
  }

  ul {
    list-style-type: none;
  }

  a {
    color: #7b7b7b;
    font-size: 4vw;
    text-decoration: underline;
  }

  .stopwatch {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;

    div {
      text-align: center;
    }
  }

  .stopwatch--btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  // span {
  //   font-family: PTSans-Bold;
  //   font-size: 16px;
  //   color: #7b7b7b;
  //   line-height: 24px;
  //   text-decoration: underline;
  // }

  li{
    text-align: left;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    -moz-hyphens: manual;
  }

  p{
    text-align: left;
  }
`
