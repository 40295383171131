import World from '../World'

class NavigationManagerInstance {
  public index = 0

  public update(newIndex: number) {
    this.index = newIndex

    const world = new World()
    world.setView(newIndex)
  }
}

const NavigationManager = new NavigationManagerInstance()
export default NavigationManager
