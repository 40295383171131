import styled from 'styled-components'

export const Container = styled.div<{ menuIndex: number}>`
  position: absolute;
  overflow-y: auto;
  top: 0;
  right: 0;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  -moz-hyphens: auto;

  z-index: -1;

  padding: 1.5em 0.5em;
  margin: 1em 0;

  width: 46%;
  height: fit-content;
  max-height: calc(100% - 156.8px - 0.5em);

  border: 1px solid #ffffff77;
  background: rgba(56, 131, 143, 0.5);
  border-radius: 10px 0 0 10px;

  text-align: center;
  color: #ffffff;

  transition: opacity 0.4s;
  -webkit-transition: opacity 0.4s;
  opacity: 0;
`



export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  -moz-hyphens: auto;



  h3,
  p,
  span,
  ul {
    margin: 0;
    padding: 0;

    text-align: center;

    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .separator {
    width: 80%;
    height: 2px;
    border: none; // 2px solid #ffffff;

    background: #ffffff;
  }

  /* Title */

  h3 {
    font-family: PTSans-Bold;
    font-size: 5vw;
    color: #ffffff;
    letter-spacing: -0.08px;
  }

  /* Text */

  p,
  ul {
    font-family: PTSans-Regular;
    font-size: 4vw;
    color: #ffffff;
  }

  a {
    color: #7b7b7b;
    font-size: 4vw;
    text-decoration: underline;
  }

  p{
    text-align: center;
  }

  #point, #rotate, #pinch{
    display: inline-block;
    position: relative;
    height: 20%;
    width: 20%;
    left: 0%;
    right: auto;
  }

  #pointp, #rotatep, #pinchp{
    display: inline-block
    position: relative;
    width: 100%;
    height: auto;
    align-text: center;
    font-size: 3.6vw;

  }

  #point img, #rotate img, #pinch img{
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
  }


`
