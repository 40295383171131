import usePrecisionTimer from "../../../../../../config/hooks/usePrecisionTimer";
import styled from "styled-components";
import { Color } from "../../../../../../config/styles/colors";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const Button = styled.button`
  border: 1.5px solid ${Color.PRIMARY};
  font-size: 18px;
  color: ${Color.PRIMARY};
  line-height: 5vw;
  border-radius: 4px;
  outline: none;
  background: #ffffff99;
  font-family: PTSans-Bold;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
`;

const Time = styled.span`
  font-family: PTSans-Bold;
  font-size: 26px;
  color: #000;
  text-decoration: none;

  // Make the number exactly the same width and avoid to move the UI when ++ or --
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
`;
const Stopwatch = () => {
  const { time, start, stop, running, done } = usePrecisionTimer();
  const { trackEvent } = useMatomo();

  const handleClick = () => {
    if (!running) {
      trackEvent({
        category: "stopwatch",
        action: "tap event",
        name: "start stopwatch",
      });

      start();
    } else {
      stop();
    }
  };

  const handleLabel = () => {
    let label = "";

    switch (true) {
      case running:
        label = "Stoppen";
        break;
      case done:
        label = "Erneut Starten";
        break;
      default:
        label = "Starten";
    }

    return label;
  };

  const handleText = () => {
    switch (true) {
      case done:
        return (
          <>
            <p>
              <b>Fertig! Mitgezählt?</b>
              <br></br>
              Verdoppeln Sie nun diese Zahl. Das Ergebnis ist Ihre Herzfrequenz
              in Schlägen pro Minute. Achten Sie darauf, ob der Puls schnell
              oder langsam schlägt und ob er regelmäßig ist.
            </p>

            <p>
              Wenn Sie Unregelmäßigkeiten spüren, zählen Sie über eine ganze
              Minute, ohne die Zahl zu verdoppeln.
            </p>
            <p>
              Tragen Sie das Ergebnis in eine Tabelle oder einen „Puls-Pass“
              ein.
            </p>
            <p>
              <span>Notieren Sie:</span>
              <ul>
                <li>• Datum</li>
                <li>• Uhrzeit</li>
                <li>• Pulsschläge pro Minute</li>
                <li>• Gleichmäßiger Schlag (ja/nein)</li>
                <li>• Sonstige Bemerkungen</li>
              </ul>
            </p>

            <p>
              Beobachten Sie Ihre Pulswerte über einen längeren Zeitraum. Wenn
              Sie Auffälligkeiten feststellen oder weitereführende Fragen haben,
              sollten Sie Ihren Arzt oder Ihre Ärztin ansprechen.
            </p>
          </>
        );

      // case running:
      //   return (
      //     <>
      //       <p>
      //         <span>
      //           <b>Die Zeit läuft - Zählen Sie mit!</b>
      //         </span>
      //         <br></br>
      //         Zählen Sie in dieser Position 30 Sekunden lang die Herzschläge und
      //         verdoppeln Sie im Anschluss die Zahl. So errechnen Sie die
      //         Herzfrequenz in Schlägen pro Minute. Achten Sie darauf, ob der
      //         Puls schnell oder langsam ist und ob der Herzrhythmus regelmäßig
      //         ist.
      //       </p>

      //       <p>
      //         Bei weiterführenden Fragen sprechen Sie mit Ihrem Arzt oder Ihrer
      //         Ärztin.
      //       </p>
      //     </>
      //   )

      default:
        return (
          <>
            <p>
              <span>
                <b>Sind Sie bereit?</b>
              </span>
              <br></br>
              Starten Sie die Uhr und zählen Sie 30 Sekunden die Pulsschläge.
              Verdoppeln Sie diese Zahl. So errechnen Sie die Herzfrequenz in
              Schlägen pro Minute. Achten Sie darauf, ob der Puls schnell oder
              langsam ist und ob der Herzrhythmus regelmäßig ist.
            </p>
          </>
        );
    }
  };

  return (
    <>
      {!done && (
        <>
          <p>
            Bei Vorhofflimmern gerät das Herz aus dem Takt, es “stolpert”. Das
            wirkt sich auch auf den Pulsschlag aus, er wird unregelmäßig. Ob Ihr
            Herz im Takt schlägt oder stolpert, können Sie in vielen Fällen
            selbst herausfinden, wenn Sie Ihren Puls am Handgelenk tasten und
            messen. Zur Überprüfung des Herzrhythmus sollte immer der Ruhepuls
            gemessen werden – zum Beispiel morgens vor dem Aufstehen oder abends
            vor dem Zubettgehen.
          </p>

          <p>Legen Sie sich eine Uhr mit Sekundenzeiger bereit.</p>

          <p>
            Bei weiterführenden Fragen sprechen Sie mit Ihrem Arzt oder Ihrer
            Ärztin.
          </p>

          <h3>Puls messen - So geht's richtig!</h3>

          <p>
            Legen Sie die Fingerkuppen von Zeige- und Mittelfinger auf die
            Unterseite des Handgelenks unter dem Daumen. Tasten Sie leicht
            umher, bis Sie den Pulsschlag spüren.
          </p>
        </>
      )}

      <p className="stopwatch">
        <div>
          <Time>{time}</Time>
        </div>

        <div className="stopwatch--btn">
          <Button id="stopwatch-btn" onClick={handleClick}>
            {handleLabel()}
          </Button>
        </div>

        {handleText()}
      </p>
    </>
  );
};

export default Stopwatch;
