import point from "../../../../../../assets/Images/point.png";
import rotate from "../../../../../../assets/Images/rotate.png";
import pinch from "../../../../../../assets/Images/pinch.png";

export const domElements = [
  <>
    <h3>Vorhofflimmern</h3>
    <hr className="separator"></hr>
    <p>
      Wählen Sie die Hotspots an, um von normalem Herzschlag zu Vorhofflimmern
      zu wechseln und Vorhofflimmern als Ursache für die Entstehung eines
      Schlaganfalls zu verstehen.
    </p>
    <p>Bei weiteren Fragen sprechen Sie mit Ihrem Arzt oder Ihrer Ärztin.</p>

    <div id="point">
      <img src={point} alt="point icon" />
    </div>
    <div id="pointp">
      Wählen Sie die<br></br>Hotspots an, um<br></br>mehr zu erfahren.
    </div>
    <hr className="separator"></hr>
    <div id="rotate">
      <img src={rotate} alt="rotate icon" />
    </div>
    <div id="rotatep">
      Drehen<br></br>
      <br></br>
    </div>
    <hr className="separator"></hr>
    <div id="pinch">
      <img src={pinch} alt="pinch icon" />
    </div>
    <div id="pinchp">
      Rein- und<br></br>Rauszoomen<br></br>
      <br></br>
    </div>
  </>,

  <>
    <h3>Haben auch Sie Symptome?</h3>

    <hr className="separator"></hr>

    <p>
      Die Symptome von Vorhofflimmern sind häufig unspezifisch. Viele Betroffene
      spüren oft gar keine Beschwerden. Bei einigen Patient:innen kann es sich
      hingegen als ein Herzrasen beziehungsweise Herzstolpern bemerkbar machen
      oder eines der folgenden Symptome auftreten.
    </p>

    <p>
      Bei weiterführenden Fragen sprechen Sie mit Ihrem Arzt oder Ihrer Ärztin.
    </p>
  </>,

  <>
    <h3>
      Vorhofflimmern<br></br>frühzeitig erkennen
    </h3>

    <hr className="separator"></hr>

    <p>
      Die Herzrhythmusstörung Vorhofflimmern ist nicht immer leicht zu erkennen.
      Denn die Erkrankung, die sich unter anderem durch Herzstolpern bemerkbar
      machen kann, kann auch nur sporadisch oder sogar ganz ohne Symptome
      auftreten. Umso wichtiger ist es, mögliche Risikofaktoren zu kennen.
    </p>

    <p>
      Bei weiterführenden Fragen sprechen Sie mit Ihrem Arzt oder Ihrer Ärztin.
    </p>
  </>,
];

export const pageTitles = ["Tab 1", "Tab 2", "Tab 3"];
