import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useEffect } from "react";
import { domElements, pageTitles } from "./data";
import { Container, Content } from "./styles";

type Props = {
  selected: number; // hotspot index
  menu: number; // menu index
};

const TextInfo = ({ selected, menu }: Props) => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({ documentTitle: pageTitles[menu] });
  }, [menu]);

  useEffect(() => {
    const opacity: string = selected === -1 ? "1" : "0";
    const zIndex: string = selected === -1 ? "auto" : "none";
    const pointerEvents: string = selected === -1 ? "1000" : "-1";
    const domInfo = document.getElementById("text-info") as HTMLDivElement;

    domInfo.style.opacity = opacity;
    domInfo.style.zIndex = zIndex;
    domInfo.style.pointerEvents = pointerEvents;
  }, [selected]);

  return (
    <Container id="text-info" menuIndex={menu}>
      <Content> {domElements[menu]}</Content>
    </Container>
  );
};

export default TextInfo;
