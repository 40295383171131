import { useEffect, useState } from "react";
import { Nullable } from "../types";

const isBooleanString = (val: string | null) => /true|false/.test(val!) //Null will return false in regex test

const getPreviousConsent = (): Nullable<boolean> => {
  const curr = window.localStorage.getItem("zappar-consent")

  if (isBooleanString(curr)) return curr === "true"
  else return null
};

const setPreviousConsent = (value: boolean) => window.localStorage.setItem("zappar-consent", value.toString())

export default function useCookiesPermissions() {
  const [cookiesConsent, setCookiesConsent] = useState<Nullable<boolean>>(null)
  const [bannerConsent, setBannerConsent] = useState<Nullable<boolean>>(null)

  useEffect(() => {
    const previouConsent = getPreviousConsent()
    if (previouConsent !== null) {
      setBannerConsent(true)
      setCookiesConsent(previouConsent)
    } else setBannerConsent(false)
  }, [])

  useEffect(() => {
    if (cookiesConsent !== null) {
      setPreviousConsent(cookiesConsent)
      setBannerConsent(true)
    }
  }, [cookiesConsent])

  return {
    cookiesConsent,
    setCookiesConsent,
    bannerConsent
  }
}