import styled from 'styled-components'
import { Color } from '../../../config/styles/colors'

export const Container = styled.div<{ step?: number }>`
  position: absolute;
  z-index: 2;
  background: #e9f3f5;

  display: flex;
  flex-direction: column;
  padding: 1em ${({ step }) => (step === 1 ? '3em' : '1.5em')};
  height: 100%;

  > :nth-child(n) {
    flex: 1;

    display: grid;
    justify-content: center;
  }

  > :last-child {
    align-items: center;
  }

  h1,
  p {
    text-align: center;
  }

  .title {
    font-family: PTSans-Bold;
    font-size: 6.8vw;
    color: #008591;
    text-align: center;
  }

  .text {
    margin-top: 0;
    padding-top: 0;

    font-family: PTSans-Regular;
    font-size: 4.2vw;
    color: #333333;
    text-align: center;
    line-height: 5.5vw;


  }

  .text-ready {
    margin-bottom: 0;
    font-family: PTSans-Regular;
    font-size: 4.4vw;
    color: #008591;
    text-align: center;
    line-height: 5vw;
    font-weight: bold;
  }

  .heart {
    
    padding-top: 1.3em;

    .container {
      width: 28vw;
      height: 28vw;

      background: #ffffff;
      border-radius: 50%;
    }

    svg {
      position: relative;
      bottom: 28%;
    }
  }

  .next {
    padding: 1em;

    button {
      outline: none;

      color: ${Color.PRIMARY};
      border 2px solid ${Color.PRIMARY};
      background: transparent;

      border-radius: 10px;
      padding: 0.8em 1.8em;

      font-family: PTSans-Regular;
      font-size: 4vw;
      font-weight: bold;
    }

    span {
      text-align: center;

      margin-top: 0.6em;

      text-decoration: underline;
      font-family: PTSans-Bold;
      font-size: 4.4vw;
      color: #7B7B7B;
      letter-spacing: -0.08px;
      line-height: 5vw;
    }

    .progress {
      opacity: 0;
      display: flex;
      flex-direction: column;
      width: 10em;
      height: auto;
      
      
      .progress-bar {
        background: ${Color.PRIMARY};
        border-radius: 10px;
      }

      .progress-text {
        text-decoration: none;
        font-size: 0.8em;
        color: #000;
        font-weight: bold;
        margin: 0;
        padding: 0.25em;
      }
    }
  }
`
