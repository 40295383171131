import { createInstance } from "@datapunt/matomo-tracker-react";

const urlBase = "https://rmhmedia.matomo.cloud"

export default createInstance({
  urlBase,
  siteId: 2,
  trackerUrl: urlBase + "/matomo.php",
  srcUrl: "https://cdn.matomo.cloud/rmhmedia.matomo.cloud/matomo.js",
  linkTracking: false, // Link tracking needs to be enabled in App.tsx
  configurations: {
    requireCookieConsent: true
  }
})


/**
* paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="https://rmhmedia.matomo.cloud/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '2']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true;
         g.src='//cdn.matomo.cloud/rmhmedia.matomo.cloud/matomo.js';
          s.parentNode.insertBefore(g,s);
      })();
*/