import * as THREE from 'three'
import { gsap } from 'gsap'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { Pane } from 'tweakpane'

import Experience from '..'
import BodyAnimations from './BodyAnimations'

import Loader from '../Utils/Loader'
import Thrombus from './Thrombus'
import Hotspot from '../Hotspot'

import bodySrc from '../../assets/models/body/Fork_Heart_Body.glb'
//import heartUnhealthySrc from '../../assets/models/body/Fork_Heart_Unhealthy.glb'
//import heartHealthySrc from '../../assets/models/body/Fork_Heart_Healthy.glb'

import { xRayShaderMaterial } from './shaders'

import EventEmitter from '../Utils/EventEmitter'
//import { convertColorMode } from '@tweakpane/core/dist/cjs/input-binding/color/model/color-model'
//import { SliderTextController } from '@tweakpane/core'
// import Gimbal from '../Utils/Gimbal'

export default class Body {
  private experience = new Experience()
  public thrombus = new Thrombus()
  // private gimbal = new Gimbal()

  private hotSpot: Hotspot

  private fitHeightDistance = 0
  private initialised = false

  private TextInfoDiv = document.getElementById("text-info") as HTMLDivElement

  //private Materials: THREE.MeshStandardMaterial[] = []
  //private heartHealthyMaterials: THREE.MeshStandardMaterial[] = []
  //private heartUnhealthyMaterials: THREE.MeshStandardMaterial[] = []

  private group = new THREE.Group()

  public bodyModel!: GLTF
  // public heartHealthyModel!: GLTF
  // public heartUnhealthyModel!: GLTF

  public xRayMaterial!: THREE.ShaderMaterial

  private animateTimeout!: NodeJS.Timeout
  //private bodyMatTween!: gsap.core.Tween
  private xRayMatTween!: gsap.core.Tween
  private pointsTween!: gsap.core.Tween
  private pointsTween2!: gsap.core.Tween

  public selected!: number
  public menuIndex!: number
  public lastMenuIndex!: number

  //private hideHandPrompt!: boolean;


  private pane: Pane

  constructor(pane: any) {
    this.pane = pane
    this.TextInfoDiv.style.opacity = '0';
    //this.hideHandPrompt = false;
    this.xRayMaterial = xRayShaderMaterial
    this.experience.scene.add(this.group)
    this.hotSpot = new Hotspot(this.group)
    // this.gimbal.enable()

    //this.UpdateSelectedHotspot('hotspot');
    this.selected = -1
    this.menuIndex = 0
    this.lastMenuIndex = 0;
    this.load()
  }

  public UpdateSelectedHotspot(str: string) {
    this.selected = Number(str.substring(str.length, str.length - 1))
    this.animate(this.menuIndex, this.selected)
  }

  public viewUpdate(data: number) {
    this.menuIndex = data
    this.reset(this.menuIndex)
    this.animate(this.menuIndex, this.selected)
  }

  private reset(menuIndex: number) {
    clearTimeout(this.animateTimeout)

    this.hotSpot.hide()

    //this.bodyMatTween.kill()
    this.xRayMatTween.kill()
    this.pointsTween?.kill()
    this.pointsTween2?.kill()

    this.thrombus.stop()

    // this.heartHealthyModel.scene.visible = menuIndex === 0
    // this.heartUnhealthyModel.scene.visible = menuIndex === 1 || menuIndex === 2

    //const materials = [];
    // ...this.bodyMaterials,
    // //...this.heartHealthyMaterials,
    // //...this.heartUnhealthyMaterials,
    //]
    // this.Materials.forEach((mat) => (mat.opacity = 0, mat.transparent = true));
    // this.xRayMaterial.uniforms.uOpacity.value = 0
  }

  private animate(menuIndex: number, selected: number) {
    this.animateTimeout = setTimeout(() => {
      const opacity = 1



      if (this.initialised) {
        if (this.lastMenuIndex !== this.menuIndex) {
          selected = 0;
          //this.hideHandPrompt = true;
        }
        if (this.lastMenuIndex !== this.menuIndex) {
          this.HideHand();
          this.ShowPoint(1)
          this.hotSpot.TurnInnerHotspotsGrey()

        } else if (selected === 0) {
          this.HideHand();
          this.ShowPoint(2)
          this.hotSpot.TurnInnerHotspotGreen(selected)

        } else if (selected === 1) {
          this.HideHand();
          this.hotSpot.TurnInnerHotspotGreen(selected)
          if (menuIndex === 0) {
            setTimeout(() => {
              this.ShowPoint(3)
            }, 11000)
          }
          else {
            this.ShowPoint(3)

          }
        } else if (selected === 2) {
          console.log('CONSOLE: HOTSPOT 2')
          this.HideHand();
          this.hotSpot.TurnInnerHotspotGreen(selected)
          //this.hideHandPrompt = true;
        }

        this.hotSpot.moveHotspots(menuIndex)

        if (menuIndex === 0 && selected === 1) {
          this.thrombus.setLoopingFalse();
          this.thrombus.repeat()
          this.thrombus.start()
          console.log('CONSOLE: RESTART HOTSPOT')

        }
        else if (menuIndex === 0 && selected === 2) {
          this.thrombus.continue()
          console.log('CONSOLE: CONTINUE HOTSPOT')
        }
        else {
          this.thrombus.stop()
          console.log('CONSOLE: STOP HOTSPOT')
        }
        switch (menuIndex) {
          case 0:
            if (this.lastMenuIndex !== this.menuIndex) {
              BodyAnimations.fadeToAction('healthy_idle')
            } else {
              BodyAnimations.fadeToAction('unhealthy_idle')
            }

            break
          case 1:
            if (this.lastMenuIndex !== this.menuIndex) {
              BodyAnimations.fadeToAction('unhealthy_idle');
            }
            break
          case 2:
            if (this.lastMenuIndex !== this.menuIndex) {
              BodyAnimations.fadeToAction('unhealthy_idle');
            }
            else if (selected === 2) {
              BodyAnimations.fadeToAction('unhealthy_pulse');
            }

            break
          default:

        }
        this.lastMenuIndex = this.menuIndex;
      }

      // Update shader xray opacity
      this.xRayMatTween = gsap.to(this.xRayMaterial.uniforms.uOpacity, {
        value: opacity,
        duration: 1,
        ease: 'power3.out',
        onComplete: () => {
          if (!this.initialised) {

            const tl = gsap.timeline()

            gsap.to(this.group.scale, {
              x: 2.5,
              y: 2.5,
              z: 2.5,
              duration: 2,
              ease: 'power2.out',
            })

            // Move group to the left side
            tl.to(this.group.position, {
              x: -1.3,
              y: '-=4.8',
              duration: 2,
              ease: 'power2.out',
              onComplete: () => {
                if (!this.initialised) {
                  const { z } = this.group.position

                  this.thrombus.setRoutes([
                    new THREE.Vector3(-1.45, 0.25, z),
                    new THREE.Vector3(-1.5, 0.085, z),
                    new THREE.Vector3(-1.6, 0.085, z),
                    new THREE.Vector3(-1.7, 0.3, z),
                    new THREE.Vector3(-1.7, 1.2, z),
                    new THREE.Vector3(-1.72, 1.65, z),
                    new THREE.Vector3(-2, 2.4, z),
                    new THREE.Vector3(-1.95, 2.7, z),
                  ])

                  this.hotSpot.createPoints([
                    new THREE.Vector2(0.13, 3.2),
                    new THREE.Vector2(0.16, 3.6),
                    new THREE.Vector2(0.08, 4.58),
                  ])

                  this.initialised = true
                  this.TextInfoDiv.style.opacity = '1';
                  EventEmitter.emit('navigation', true)
                  BodyAnimations.fadeToAction('healthy_idle', 0)
                }
              },
            })
          }
        },
      })
    }, 250)
  }

  private ShowPoint(num: number) {
    for (let i = 0; i < num; i++) {
      this.pointsTween = gsap.to([this.hotSpot.parentMats[i], this.hotSpot.innerMats[i], this.hotSpot.glowMats[i]], {
        opacity: 1,
        depthTest: true,
        duration: 1,
        ease: 'expo.out',
      })
    }

    for (let i = num; i < 3; i++) {
      this.pointsTween = gsap.to([this.hotSpot.parentMats[i], this.hotSpot.innerMats[i], this.hotSpot.glowMats[i]], {
        opacity: 0,
        depthTest: true,
        duration: 1,
        ease: 'expo.out',
      })
    }
    setTimeout(() => {
      //if(!this.hideHandPrompt){
      this.pointsTween = gsap.to(this.hotSpot.handMats[num - 1], {
        opacity: 1,
        depthTest: true,
        duration: 1,
        ease: 'expo.out',
      })
      //}
    }, 1000);

  }

  private HideHand() {
    for (let i = 0; i < 3; i++) {
      this.pointsTween = gsap.to(this.hotSpot.handMats[i], {
        opacity: 0,
        depthTest: true,
        duration: 1,
        ease: 'expo.out',
      })
    }
  }



  private async load() {
    const loader = new GLTFLoader(Loader)

    const [body] = await Promise.all([
      loader.loadAsync(bodySrc),
      // loader.loadAsync(heartHealthySrc),
      // loader.loadAsync(heartUnhealthySrc),
    ])

    /**
     * BODY
     */
    if (body) {
      this.bodyModel = body

      this.bodyModel.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          switch (child.name) {
            case 'Fork_Heart_Body_UV1':
              // materialBody.blending = THREE.NormalBlending
              // materialBody.roughness = 0.6
              //materialBody.transparent = true
              //materialBody.opacity = 0
              //materialBody.color = new THREE.Color('#0044ff')
              //child.material = materialBody
              child.renderOrder = 0
              //this.Materials.push(child.material)
              break
            case 'Fork_Heart_Veins_UV':
              const materialVeins = (
                child.material as THREE.MeshStandardMaterial
              ).clone() as THREE.MeshStandardMaterial
              const veinsMap = materialVeins.map
              // child.material = new THREE.MeshStandardMaterial()
              // child.material.roughness = materialVeins.roughness
              // child.material.metalness = materialVeins.metalness
              child.material.map = veinsMap
              materialVeins.transparent = true
              //materialVeins.opacity = 0
              child.material = materialVeins
              child.renderOrder = 1
              //this.Materials.push(child.material)
              break
            case 'Heart_Uptodate1':
              const materialHeart = (
                child.material as THREE.MeshStandardMaterial
              ).clone()
              // materialBody.blending = THREE.NormalBlending
              // materialBody.roughness = 0.6
              materialHeart.transparent = true
              //materialHeart.opacity = 0
              child.material = materialHeart
              child.renderOrder = 1
              //this.Materials.push(child.material)
              break
            case 'Brain_Healthy':
              const materialBrain = (
                child.material as THREE.MeshStandardMaterial
              ).clone()

              materialBrain.transparent = true
              child.material = this.xRayMaterial // brainMaterialClone
              child.renderOrder = 1
              //this.Materials.push(child.material)
              break
            default:
          }
        }
      })

      BodyAnimations.add(this.bodyModel, 'healthy_idle')
      BodyAnimations.add(this.bodyModel, 'unhealthy_idle')
      BodyAnimations.add(this.bodyModel, 'unhealthy_pulse')

      this.group.add(this.bodyModel.scene)
    }

    /**
     * HEALTHY HEART
     */
    // if (heartHealthy) {
    //   this.heartHealthyModel = heartHealthy
    //   this.heartHealthyModel.scene.visible = true
    //   this.heartHealthyModel.scene.renderOrder = 1

    //   BodyAnimations.add(this.heartHealthyModel, 'h_healthy')

    //   this.heartHealthyModel.scene.traverse((child) => {
    //     if (child instanceof THREE.Mesh) {
    //       const material = (
    //         child.material as THREE.MeshStandardMaterial
    //       ).clone() as THREE.MeshStandardMaterial

    //       const map = material.map as THREE.Texture
    //       map.encoding = THREE.sRGBEncoding
    //       map.format = THREE.RGBFormat

    //       child.material = new THREE.MeshStandardMaterial()

    //       child.material.map = map
    //       child.material.transparent = true
    //       child.material.opacity = 0
    //       child.material.roughness = material.roughness
    //       child.material.metalness = material.metalness

    //       this.heartHealthyMaterials.push(child.material)
    //     }
    //   })

    //   this.group.add(this.heartHealthyModel.scene)
    // }

    // /**
    //  * UNHEALTHY HEART
    //  */
    // if (heartUnhealthy) {
    //   this.heartUnhealthyModel = heartUnhealthy
    //   this.heartUnhealthyModel.scene.visible = false
    //   this.heartUnhealthyModel.scene.renderOrder = 1

    //   BodyAnimations.add(this.heartUnhealthyModel, 'h_unhealthy')

    //   this.heartUnhealthyModel.scene.traverse((child) => {
    //     if (child instanceof THREE.Mesh) {
    //       const material = (
    //         child.material as THREE.MeshStandardMaterial
    //       ).clone() as THREE.MeshStandardMaterial

    //       const map = material.map as THREE.Texture
    //       map.encoding = THREE.sRGBEncoding
    //       map.format = THREE.RGBFormat

    //       child.material = new THREE.MeshStandardMaterial()

    //       child.material.map = map
    //       child.material.transparent = true
    //       child.material.opacity = 0
    //       child.material.roughness = material.roughness
    //       child.material.metalness = material.metalness

    //       this.heartUnhealthyMaterials.push(child.material)
    //     }
    //   })

    //   this.group.add(this.heartUnhealthyModel.scene)
    // }

    /**
     * Fit the model to the screen
     *
     * TODO: need to check ~ timeout for ios or it's not working ?!
     */
    setTimeout(() => {
      // Center group
      const groupBox = new THREE.Box3().setFromObject(this.group)
      const center = new THREE.Vector3()
      groupBox.getCenter(center)
      this.group.position.sub(center)

      // Fit the model into the screen
      const camera = this.experience.camera.instance

      const fov =
        (2 * Math.atan(1 / camera.projectionMatrix.elements[5]) * 180) / Math.PI // convert vertical fov to radians

      const box = new THREE.Box3()
      box.expandByObject(this.bodyModel.scene)

      const size = box.getSize(new THREE.Vector3())
      const maxSize = Math.max(size.x, size.y, size.z)
      this.fitHeightDistance = maxSize / (2 * Math.atan((Math.PI * fov) / 360))

      // const { screen } = this.experience.config
      // const fitRatio = 1.2
      // const aspect = screen.width / screen.height
      // const fitWidthDistance = this.fitHeightDistance / aspect
      // const distance =
      //   fitRatio * Math.max(this.fitHeightDistance, fitWidthDistance)

      this.group.position.z = -this.fitHeightDistance
      this.group.position.y = -5
      this.group.scale.multiplyScalar(1.5)

      this.animate(this.menuIndex, this.selected)

      EventEmitter.emit('model.loaded', this.group)
    }, 1000)
  }

  private gimbalUpdate() {
    // this.gimbal.update()
    // if (this.gimbal.yaw) {
    //   const angle = -Math.PI * 1.7
    //   this.group.rotation.y = this.gimbal.yaw / 2 + angle
    // }
  }

  public update() {
    const delta = this.experience.deltaTime

    BodyAnimations?.update(delta)

    // this.gimbalUpdate()
  }
}
