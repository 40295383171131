import Stopwatch from "./Stopwatch";

const menu1 = [
  <>
    <h3>Vorhofflimmern</h3>

    <hr className="separator"></hr>
    <p>
      Vorhofflimmern ist eine Herzerkrankung, die dazu führen kann, dass das
      Herz schnell und unregelmäßig schlägt. Im gesunden Herzen sorgen
      elektrische Impulse aus dem sogenannten Sinusknoten dafür, dass sich die
      Vorhöfe des Herzens im Takt zusammenziehen und so das Blut weiter in die
      Herzkammern pumpen. Beim Vorhofflimmern stören fehlerhafte Impulse diesen
      geordneten Ablauf, der Herzschlag gerät aus dem Takt. Das ist oft als
      „Herzstolpern“ zu spüren.
    </p>
  </>,

  <>
    <h3 className="space-top">
      Ein Thrombus<br></br>entsteht
    </h3>

    <hr className="separator"></hr>

    <p>
      Wenn die Vorhöfe des Herzens flimmern, kann das Blut nicht mehr richtig
      durch das Herz gepumpt werden. Dadurch verlangsamt sich die
      Fließgeschwindigkeit des Blutes in den Vorhöfen, was die Bildung von
      Blutgerinnseln, sogenannten Thromben, begünstigt.
    </p>
  </>,

  <>
    <h3 className="space-top">Schlaganfall</h3>

    <hr className="separator"></hr>

    <p>
      Löst sich ein solches Blutgerinnsel, kann es durch den Blutkreislauf in
      das Gehirn geschwemmt werden und dort eine Arterie blockieren, was zu
      einem Schlaganfall führt.
    </p>
  </>,
];

const menu2 = [
  <>
    <h3>
      Allgemeine<br></br>Symptome
    </h3>

    <hr className="separator"></hr>

    <p>Allgemeine Symptome, die auf Vorhofflimmern hindeuten können, sind:</p>

    <ul>
      <li>• Allgemeine Leistungsschwäche und schnelle Erschöpfung</li>
      <li>• Unregelmäßiger Puls</li>
      <li>• Unruhe</li>
      <li>• Vermehrter Harndrang</li>
      <li>• Vermehrtes Schwitzen</li>
    </ul>
  </>,
  <>
    <h3>Symptome im Brustbereich</h3>

    <hr className="separator"></hr>

    <p>
      Symptome im Brustbereich, die auf Vorhofflimmern hindeuten können, sind:
    </p>

    <ul>
      <li>• Herzrasen oder auch "Herzstolpern"</li>
      <li>• Atemnot/Kurzatmigkeit</li>
      <li>• Schmerzen im Brustkorb</li>
      <li>• Engegefühl in der Brust</li>
    </ul>
  </>,
  <>
    <h3>Neurologische Symptome</h3>

    <hr className="separator"></hr>

    <p>
      Neurologische Symptome, die auf Vorhofflimmern hindeuten können, sind:
    </p>

    <ul>
      <li>• Antriebslosigkeit und Erschöpfung</li>
      <li>• kurzzeitige Bewusstlosigkeit</li>
      <li>• Angst oder innere Unruhe</li>
      <li>• Schwindelgefühl</li>
      <li>• Gedächtnis- und Konzentrationsstörungen</li>
      <li>• Schlafstörungen</li>
    </ul>
  </>,
];

const menu3 = [
  <>
    <h3>
      Risikofaktoren<br></br>kennen und<br></br>erkennen!
    </h3>

    <hr className="separator"></hr>

    <p>
      Das Alter gilt als einer der wesentlichen Risikofaktoren für das Auftreten
      von Vorhofflimmern (ab 65 Jahre). Weitere Risikofaktoren sind vor allem
      Vorerkrankungen wie:
    </p>

    <ul>
      <li>• Bluthochdruck</li>
      <li>• Schilddrüsenüberfunktion (Hyperthyreose)</li>
      <li>• Koronare Herzkrankheit (KHK)</li>
      <li>• Herzschwäche und/oder Herzfehler</li>
      <li>• Atemaussetzer im Schlaf (Schlafapnoe)</li>
      <li>• Chronisch-obstruktive Lungenerkrankung (COPD)</li>
      <li>• Diabetes mellitus</li>
      <li>• Erbliche Vorbelastung</li>
    </ul>
  </>,

  <>
    <h3>
      Risikofaktoren im<br></br>Alltag kennen<br></br>und erkennen!
    </h3>

    <hr className="separator"></hr>

    <p>
      Auch der Lebensstil kann großen Einfluss auf die Herzgesundheit haben. Das
      Risiko für Vorhofflimmern kann steigen durch:
    </p>

    <ul>
      <li>• Starkes Übergewicht</li>
      <li>• Zigarettenkonsum</li>
      <li>• Übermäßigen Alkoholkonsum</li>
      <li>• Stress</li>
      <li>• Hochleistungssport</li>
      <li>• Bewegungsmangel</li>
    </ul>
  </>,
  <>
    <h3>
      Anzeichen für<br></br>Vorhofflimmern<br></br>erkennen durch<br></br>
      Pulsmessen
    </h3>

    <hr className="separator"></hr>

    <Stopwatch />
  </>,
];

export const domElements = [menu1, menu2, menu3];

export const eventsNames = [
  ["Vorhofflimmern", "Ein Thrombus entsteht", "Schlaganfall"],
  ["Allgemeine Symptome", "Symptome im Brustbereich", "Neurologische Symptome"],
  [
    " Risikofaktoren kennen und erkennen",
    " Risikofaktoren im Alltag kennen und erkennen",
    "Anzeichen für Vorhofflimmern erkennen durch Pulsmessen",
  ],
];
