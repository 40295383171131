import * as styled from 'styled-components'

import { Color } from './colors'

import PTSansBold from './fonts/PTSans-Bold.ttf'
import PTSansRegular from './fonts/PTSans-Regular.ttf'

export default styled.createGlobalStyle`
  @font-face {
    font-family: PTSans-Bold;
    src: url(${PTSansBold}) format('truetype');
  }
  @font-face {
    font-family: PTSans-Regular;
    src: url(${PTSansRegular}) format('truetype');
  }

  html,
  body,
  #root {
    margin: 0;
    padding: 0;

    background: #e9f3f5;

    width: 100%;
    height: 100%;

    font-family: Arial, Helvetica, Arial, sans-serif;
  }

  .unselect {
    pointer-events: none;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .link {
    font-size: bold;
    color: ${Color.SECONDARY};

    :hover {
      color: #000;
    }
  }

  .point {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;

    outline: none;

    background: transparent;
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }

  .point .circle {
    cursor: help;

    transform: scale(0, 0);
    transition: transform 0.3s;

    background-color: ${Color.PRIMARY};
    border-radius: 50%;

    width: 5px;
    height: 5px;
  }

  .point.visible .circle {
    transform: scale(1, 1);

    /* iphone 6, 6s, 7, 8 */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
      transform: scale(0.9, 0.9);
    }
  }

  .point:hover .text {
    opacity: 1;
  }

  .ring-container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 20;

    opacity: 0;
  }

  .ring-circle {
    position: absolute;
    top: 23px;

    left: 23px;

    width: 1.05em;
    height: 1.05em;

    border-radius: 50%;
    // background-color: #fff;

    box-shadow: 0 0 0.85em 0.35em red, 0 0 0.9em 0.8em red, 0 0 1.5em 1.5em red;

    background-color: #a7281c;
    // box-shadow: 0 0 0.85em 0.35em #701c14, 0 0 0.9em 0.8em #a7281c,
    //   0 0 1.5em 1.5em #bb3c2f;

    transform: scale(0.45, 0.45);
  }

  .ringring {
    border: 3px solid #a7281c;
    -webkit-border-radius: 30px;

    height: 25px;
    width: 25px;
    position: absolute;
    left: 16px;
    top: 16px;

    -webkit-animation: pulsate 1s infinite ease-out;
    // -webkit-animation-iteration-count: infinite;

    opacity: 0;
  }

  @-webkit-keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
`
