import * as ZapparThree from '@zappar/zappar-threejs-dev'

export const ZapparPermissionRquest = async (
  callback: (value: boolean | null) => void,
) => {
  const granted = await ZapparThree.permissionRequest()
  if (granted) setTimeout(() => callback(true), 300)
  else {
    //ZapparThree.permissionDeniedUI()
    setTimeout(() => callback(false), 300)
  }

}
