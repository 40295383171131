// import gsap from 'gsap'
import { useEffect, useState } from 'react'
import Loader from '../../experience/Utils/Loader'

const useProgress = (granted: boolean | null) => {
  const [progress, setProgress] = useState(0)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (granted) {
      Loader.onProgress = (_, loaded, total) => {
        const newProgress = (loaded / total) * 100
        setProgress(newProgress)
      }
    }
  }, [granted])

  useEffect(() => {
    if (progress >= 100) {
      setReady(true)
      //   gsap.to('#splash-screen', {
      //     opacity: 0,
      //     duration: 1,
      //     ease: 'power3.out',
      //     delay: 0.5,
      //     onComplete: () => {
      //       const layout = document.getElementById(
      //         'splash-screen'
      //       ) as HTMLDivElement
      //       layout.style.display = 'none'
      //     },
      //   })
    }
  }, [progress])

  return { ready, progress }
}

export default useProgress
