import * as ZapparThree from '@zappar/zappar-threejs-dev'
import Experience from '.'
import * as THREE from 'three'
export default class Camera {
  private experience: Experience

  public instance!: ZapparThree.Camera

  constructor() {
    this.experience = new Experience()

    this.setInstance()
  }

  private setInstance() {
    this.instance = new ZapparThree.Camera()
    this.instance.backgroundTexture.encoding = THREE.sRGBEncoding
  }

  public start(value = true) {
    this.instance.start(value)
  }

  public update() {
    if (this.experience.renderer) {
      this.instance.updateFrame(this.experience.renderer.instance)
    }
  }
}
