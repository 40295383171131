import * as THREE from 'three'

const vertex = ` 
    uniform float uPower;

    varying float vIntensity;

    ${THREE.ShaderChunk.skinning_pars_vertex}

    void main() {
        vec3 vNormal = normalize(normalMatrix * normal);
        
        mat4 modelViewProjectionMatrix = projectionMatrix * modelViewMatrix;

        ${THREE.ShaderChunk.beginnormal_vertex}
        ${THREE.ShaderChunk.skinbase_vertex}
        ${THREE.ShaderChunk.skinnormal_vertex}
    
        vec3 transformed = vec3(position);
    
        ${THREE.ShaderChunk.skinning_vertex}
    
        gl_Position = modelViewProjectionMatrix * vec4(transformed, 1.0);

        vIntensity = pow(1.0 - abs(dot(vNormal, vec3(0.0, 0.0, 1.0))), uPower);
    }
`

export default vertex
