import { Color } from '../../../../../../../config/styles/colors'
import { TabMenu } from '../../../../../../../config/types'

const Menu2 = ({ active }: TabMenu) => {
  return (
    
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Icon 2</title>
      <defs>
        <polygon id="path-1" points="0 0 32 0 32 32 0 32"></polygon>
      </defs>
      <g
        id="Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Pfizer_AR_mobile4G"
          transform="rotate(180 0 0) scale(-0.0165 0.0165) translate(-440 -2400)"
        >
          <g id="Icon-2" >
            <g id="Group-2">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Clip-2"></g>
             
            </g>
            
            <path
              d="m 1417.32,482.719 c 516.17,0 934.61,418.441 934.61,934.611 0,516.17 -418.44,934.6 -934.61,934.6 -516.168,0 -934.601,-418.43 -934.601,-934.6 0,-516.17 418.433,-934.611 934.601,-934.611"
              fill={active ? Color.PRIMARY : '#FFFFFF' }
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              id="path14" 
            ></path>   
            <path
              d="m 1417.32,482.719 c 516.17,0 934.61,418.441 934.61,934.611 0,516.17 -418.44,934.6 -934.61,934.6 -516.168,0 -934.601,-418.43 -934.601,-934.6 0,-516.17 418.433,-934.611 934.601,-934.611 z"
              fill="none"
              stroke={active ? '#FFFFFF' : Color.PRIMARY}
              strokeWidth="50"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeDasharray="none"
              strokeOpacity="1"
              id="path22" 
            ></path>
            <path
            d="m 1181.57,2140.91 c -7.76,106.71 89.22,127.41 175.07,87.59 85.85,-39.81 46.77,-122.63 31.8,-154.49"
              fill={active ? Color.PRIMARY : '#FFFFFF' }
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              id="path24" 
            ></path>
            <path
              d="m 1181.57,2140.91 c -7.76,106.71 89.22,127.41 175.07,87.59 85.85,-39.81 46.77,-122.63 31.8,-154.49"
              fill="none"
              stroke={active ? '#FFFFFF' : Color.PRIMARY}
              strokeWidth="40"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeDasharray="none"
              strokeOpacity="1"
              id="path32" 
            ></path>
            <path
              d="m 1349.88,1789.73 c 118.15,18.11 328.62,-11.71 406.65,-83.57 78.03,-71.86 208.65,-392.02 230.6,-461.45 10.97,-33.8 -149.86,-81.16 -196.5,-53.15 -4.39,-27.61 -17.37,-93.9 -0.5,-209.771 18.29,-125.57 28.52,-201.508 28.52,-201.508 -3.96,-40.633 -73.18,-85.359 -197.05,-102.109 -202.11,-27.332 -500.54,-11.094 -502.7,41.219 0,72.129 19.04,102.121 20.27,179.179 1.77,110.7 12,130.6 -36.88,233.29 -12.05,-65.98 -19.04,-115 -19.04,-115 -116.047,0 -204.621,4.67 -246.07,54.61 2.797,120.15 22.054,179.23 30.758,259.87 19.574,108.98 18.746,192.81 53.128,243.1 34.137,49.94 83.224,88.68 167.904,126.37 76.2,33.91 159.28,73.34 260.91,88.92"
              fill={active ? Color.PRIMARY : '#FFFFFF' }
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              id="path34" 
            ></path>
            <path
              d="m 1349.88,1789.73 c 118.15,18.11 328.62,-11.71 406.65,-83.57 78.03,-71.86 208.65,-392.02 230.6,-461.45 10.97,-33.8 -149.86,-81.16 -196.5,-53.15 -4.39,-27.61 -17.37,-93.9 -0.5,-209.771 18.29,-125.57 28.52,-201.508 28.52,-201.508 -3.96,-40.633 -73.18,-85.359 -197.05,-102.109 -202.11,-27.332 -500.54,-11.094 -502.7,41.219 0,72.129 19.04,102.121 20.27,179.179 1.77,110.7 12,130.6 -36.88,233.29 -12.05,-65.98 -19.04,-115 -19.04,-115 -116.047,0 -204.621,4.67 -246.07,54.61 2.797,120.15 22.054,179.23 30.758,259.87 19.574,108.98 18.746,192.81 53.128,243.1 34.137,49.94 83.224,88.68 167.904,126.37 76.2,33.91 159.28,73.34 260.91,88.92 z"
              fill="none"
              stroke={active ? '#FFFFFF' : Color.PRIMARY}
              strokeWidth="40"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeDasharray="none"
              strokeOpacity="1"
              id="path42" 
            ></path>
            <path
              d="m 1480,1881.49 c -3.63,38.65 -28.41,46.74 -53.07,18.19 -12.35,96.25 -53.05,209.49 -192.2,191.8 -141.96,-18.05 -184.44,-153.49 -182.25,-216.14 2.19,-62.65 47.09,-189.19 99.74,-241.08 49.77,-46.89 86.62,-84.06 121.05,-77.81 34.85,6.33 60.2,28.47 116,88.24 22.33,31.46 35.9,63.93 41.04,113.99 45.59,6.79 53.53,81.97 49.69,122.81"
              fill={active ? Color.PRIMARY : '#FFFFFF' }
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              id="path44" 
            ></path>
            <path
              d="m 1480,1881.49 c -3.63,38.65 -28.41,46.74 -53.07,18.19 -12.35,96.25 -53.05,209.49 -192.2,191.8 -141.96,-18.05 -184.44,-153.49 -182.25,-216.14 2.19,-62.65 47.09,-189.19 99.74,-241.08 49.77,-46.89 86.62,-84.06 121.05,-77.81 34.85,6.33 60.2,28.47 116,88.24 22.33,31.46 35.9,63.93 41.04,113.99 45.59,6.79 53.53,81.97 49.69,122.81 z"
              fill="none"
              stroke={active ? '#FFFFFF' : Color.PRIMARY}
              strokeWidth="30"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeDasharray="none"
              strokeOpacity="1"
              id="path52" 
            ></path>
            <path
              d="m 1096.8,2112.62 c -52.01,-43.99 -93.82,-133.42 -95.78,-197.42 -1.11,-36.36 19,-52.84 54.34,-64.13 0,0 -23.76,70.63 44.37,147.61 34.84,-101.45 312.16,-148.22 332.74,-89.56 2.83,8.05 41.17,30.91 48.29,-27.74 3.75,13.6 17.47,81.75 -6.33,137.91 -69.67,164.33 -192.27,159.78 -260.61,149.67 -26.74,-3.95 -77.41,-18.22 -117.02,-56.34"
              fill={active ? Color.PRIMARY : '#FFFFFF' }
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              id="path54" 
            ></path>
            <path
              d="m 1096.8,2112.62 c -52.01,-43.99 -93.82,-133.42 -95.78,-197.42 -1.11,-36.36 19,-52.84 54.34,-64.13 0,0 -23.76,70.63 44.37,147.61 34.84,-101.45 312.16,-148.22 332.74,-89.56 2.83,8.05 41.17,30.91 48.29,-27.74 3.75,13.6 17.47,81.75 -6.33,137.91 -69.67,164.33 -192.27,159.78 -260.61,149.67 -26.74,-3.95 -77.41,-18.22 -117.02,-56.34 z"
              fill="none"
              stroke={active ? '#FFFFFF' : Color.PRIMARY}
              strokeWidth="30"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeDasharray="none"
              strokeOpacity="1"
              id="path62" 
            ></path>
            <path
              d="m 1101.5,1131.15 c -25.99,59.32 -48.16,84.54 -57.79,161.8"
              fill={active ? Color.PRIMARY : '#FFFFFF' }
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              id="path64" 
            ></path>
            <path
              d="m 1101.5,1131.15 c -25.99,59.32 -48.16,84.54 -57.79,161.8"
              fill="none"
              stroke={active ? '#FFFFFF' : Color.PRIMARY}
              strokeWidth="30"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeDasharray="none"
              strokeOpacity="1"
              id="path72" 
            ></path>
            <path
              d="m 1760.16,1197.67 c -60.18,-33.3 -163.44,-92.42 -199.97,-91.11 -57.72,1.86 -95.67,58.62 -139.16,73.02 -62.81,20.79 -146.62,-28.41 -164.45,-64.55 -17.83,-36.14 131.99,-126.061 186.59,-144.991 44.28,-15.34 121.76,-4.758 270.9,22.231 143.87,24.88 204.59,54.54 261.11,101.88 19.17,16.06 26.77,42.05 19.65,66.14 -5.14,17.42 -20.25,59.59 -20.25,59.59 -71.24,-22.21 -134.1,22.22 -214.42,-22.21"
              fill={active ? Color.PRIMARY : '#FFFFFF' }
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              id="path74" 
            ></path>
            <path
              d="m 1760.16,1197.67 c -60.18,-33.3 -163.44,-92.42 -199.97,-91.11 -57.72,1.86 -95.67,58.62 -139.16,73.02 -62.81,20.79 -146.62,-28.41 -164.45,-64.55 -17.83,-36.14 131.99,-126.061 186.59,-144.991 44.28,-15.34 121.76,-4.758 270.9,22.231 143.87,24.88 204.59,54.54 261.11,101.88 19.17,16.06 26.77,42.05 19.65,66.14 -5.14,17.42 -20.25,59.59 -20.25,59.59 -71.24,-22.21 -134.1,22.22 -214.42,-22.21 z"
              fill="none"
              stroke={active ? '#FFFFFF' : Color.PRIMARY}
              strokeWidth="30"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeDasharray="none"
              strokeOpacity="1"
              id="path82" 
            ></path>
            <path
              d="m 1742.71,1206.58 c -16.61,36.25 -47.13,104.75 -60.6,154.36"
              fill="none"
              stroke={active ? '#FFFFFF' : Color.PRIMARY}
              strokeWidth="30"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeDasharray="none"
              strokeOpacity="1"
              id="path90" 
            ></path>
            <path
              d="m 993.609,1023.39 c 86.831,69.96 250.121,107.78 306.331,170.45 39.8,64.39 93.55,133.97 93.55,133.97 49.02,44.28 77.11,55.93 117.01,66.42 39.9,10.48 117,30.04 113.84,-49.81 -1.98,-49.93 -25.87,-83.49 -91.71,-146.28 -9.73,-11.04 -80.13,-78.11 -110.67,-96.46 -30.54,-18.35 -165.61,-132.668 -236.63,-184.641 -95.28,-69.73 -220.228,-91.93 -271.752,-90.609 -37.164,0.961 -69.879,104.531 -62.719,230.46 39.715,-21.64 51.684,-26.78 142.75,-33.5"
              fill={active ? Color.PRIMARY : '#FFFFFF' }
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              id="path92" 
            ></path>
            <path
              d="m 993.609,1023.39 c 86.831,69.96 250.121,107.78 306.331,170.45 39.8,64.39 93.55,133.97 93.55,133.97 49.02,44.28 77.11,55.93 117.01,66.42 39.9,10.48 117,30.04 113.84,-49.81 -1.98,-49.93 -25.87,-83.49 -91.71,-146.28 -9.73,-11.04 -80.13,-78.11 -110.67,-96.46 -30.54,-18.35 -165.61,-132.668 -236.63,-184.641 -95.28,-69.73 -220.228,-91.93 -271.752,-90.609 -37.164,0.961 -69.879,104.531 -62.719,230.46 39.715,-21.64 51.684,-26.78 142.75,-33.5 z"
              fill="none"
              stroke={active ? '#FFFFFF' : Color.PRIMARY}
              strokeWidth="30"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeDasharray="none"
              strokeOpacity="1"
              id="path100" 
            ></path>
            <path
              d="m 1283.12,1330.86 -123.69,40.4 63.12,53.08 -106.48,75.15"
              fill={active ? Color.PRIMARY : '#FFFFFF' }
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              id="path102" 
            ></path>
            <path
              d="m 1283.12,1330.86 -123.69,40.4 63.12,53.08 -106.48,75.15"
              fill="none"
              stroke={active ? '#FFFFFF' : Color.PRIMARY}
              strokeWidth="30"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeDasharray="none"
              strokeOpacity="1"
              id="path110" 
            ></path>
            <path
              d="m 1238.14,1228.23 -94.33,8.88 35.38,47.55 -82.6,26.45"
              fill={active ? Color.PRIMARY : '#FFFFFF' }
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              id="path112" 
            ></path>
            <path
              d="m 1238.14,1228.23 -94.33,8.88 35.38,47.55 -82.6,26.45"
              fill="none"
              stroke={active ? '#FFFFFF' : Color.PRIMARY}
              strokeWidth="30"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeDasharray="none"
              strokeOpacity="1"
              id="path120" 
            ></path>
            
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Menu2
