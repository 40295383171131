import { gsap } from 'gsap'

import EventEmitter from '../../../../../../experience/Utils/EventEmitter'

import { domElements } from './data'

import { Container, Content } from './styles'

type Props = {
  id: string // card id
  index: number // card index
  selected: number // hotspot index
  menu: number // menu index
}

const Card = ({ id, index, selected, menu }: Props) => {
  const isSelected = index === selected

  gsap.to(`#${id}`, {
    duration: isSelected ? 0.5 : 0,
    opacity: isSelected ? 1 : 0,
    zIndex: isSelected ? 999 : -1,
    ease: 'power3.inOut',
  })

  const handleClose = () => {
    if (menu === 2 && selected === 2) {
      EventEmitter.emit('stopwatch.stop', true)
    }
    EventEmitter.emit('hotspot.close', index)
  }

  return (
    <Container {...{ id }}>
      <span className="close-btn" onClick={handleClose}></span>

      <Content>{domElements[menu][selected]}</Content>
    </Container>
  )
}

export default Card
