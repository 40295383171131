import { RotateDirection } from '../config/hooks/useDeviceOrientation'

export type TransformStyle = {
  value: DeviceMotionEventRotationRate
  transform: string
}

export class DeviceOrientationOptionProcess {
  private orientation!: DeviceMotionEventRotationRate
  private transformStyle!: TransformStyle

  setData(orientation: DeviceMotionEventRotationRate) {
    this.orientation = orientation
    this.transformStyle = {
      value: {
        beta: 0,
        gamma: 0,
        alpha: 0,
      },
      transform: '',
    }

    return this
  }

  adjustByCorrection(enable: boolean, initialData: any) {
    if (!enable) {
      return this
    }

    this.orientation = {
      alpha: this.orientation.alpha || 0 - initialData.alpha,
      beta: this.orientation.beta || 0 - initialData.beta,
      gamma: this.orientation.gamma || 0 - initialData.gamma,
    }

    this.transformStyle = {
      value: {
        beta: this.orientation.beta,
        gamma: this.orientation.gamma,
        alpha: this.orientation.alpha,
      },
      transform: `rotateX(${this.orientation.beta}deg) rotateY(${this.orientation.gamma}deg) rotateZ(${this.orientation.alpha}deg)`,
    }

    return this
  }

  adjustDirection(
    transformDirections: [RotateDirection, RotateDirection, RotateDirection]
  ) {
    const [directionX, directionY, directionZ] = transformDirections
    function getDirectionValue(
      degree: number,
      direction: RotateDirection
    ): number {
      if (direction === RotateDirection.NONE) {
        return degree
      }

      return degree * -1
    }

    const beta = getDirectionValue(this.orientation.beta || 0, directionX)
    const gamma = getDirectionValue(this.orientation.gamma || 0, directionY)
    const alpha = getDirectionValue(this.orientation.alpha || 0, directionZ)

    this.transformStyle = {
      value: {
        beta,
        gamma,
        alpha,
      },
      transform: `rotateX(${beta}deg) rotateY(${gamma}deg) rotateZ(${alpha}deg)`,
    }

    return this
  }

  commit() {
    return {
      orientation: this.orientation,
      transformStyle: this.transformStyle,
    }
  }
}

export const processDeviceOrientationOption =
  new DeviceOrientationOptionProcess()
